import React, {Component} from 'react'
// export const 

export class CanvasElement extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.ctx = this.refs.canvas.getContext("2d")
		this.ctx.scale(2,2)
		this.ctx.lineWidth = 1.5;
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.points.length <= 0 || nextProps.showWork) {return}
	    this.ctx.clearRect(0, 0, this.props.width, this.props.height);
	    this.ctx.beginPath();
	    this.ctx.moveTo(nextProps.points[0][0], nextProps.points[0][1])
			
	    for (var i = 1 ; i < nextProps.points.length; i += 2) {
	    	this.ctx.lineTo(nextProps.points[i][0], nextProps.points[i][1])
	    	if (nextProps.points[i + 1]) {
	    		this.ctx.moveTo(nextProps.points[i+1][0], nextProps.points[i+1][1])
	    	}
	    }

	    this.ctx.stroke()

	}
	
	shouldComponentUpdate(nextProps, nextState) {
		return nextProps.display !== this.props.display
	}

	render() {
		let width = this.props.width * 2;
		let height = this.props.height * 2;
		let background = this.props.background ? "#efefef" : "transparent";
		let load = this.props.background ? "load" : ""
		
		let display = this.props.display ? " " : "invisible "
		let loadClass = this.props.background ? "loading" : ""
		var styles = {
			width: width / 2,
			height: height / 2,
			background: background,
		}

		return(
			 <canvas ref="canvas" className={"canvas " + display + loadClass} style={styles} width={width} height={height}/>
		)
	}
}

export class LoadingCharacters extends Component {
	constructor(props) {
		super(props);
		this.characters = ["L", "O", "A", "D", "I" ,"N", "G"];
		this.positions = [];
	}

	
	render() {
		let that = this;
		let transforms = this.characters.map((item, i) => [initialCoordsPercent[i][0] / 100 * this.props.width, initialCoordsPercent[i][1] / 100 * this.props.height]);

		return(
			<div className="characterContainer">
				{this.characters.map((item, i) => <TextElement display={that.props.display} key={"lo" + i}textRef = {el => this['text' + i] = el} word={this.characters[i]} transform={transforms[i]}></TextElement>)}
			</div>
		)
	}
}

export class TextElement extends Component {
	constructor(props) {
		super(props);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true
	}

	render() {
		let x = this.props.transform ? this.props.transform[0] : 0;
		let y = this.props.transform ? this.props.transform[1] : 0;
		let transform = "translate3d("+x+"px,"+y+"px,0)";
		// let display = this.props.display ? "block" : "none"
		let display = this.props.display ? "" : "invisible"

		let styles = { 
			transform: transform,
		};

		return(
				<h1 style={styles} ref={this.props.textRef} className={"text-element load-text " + display}>{this.props.word}</h1>
		) 
	}

}


var initialCoordsPercent =
    [
      [3.8194444444444446, 10.8125],
      [28.194444444444443, 10.8125],
      [53.645833333333336, 17.0625],
      [69.72222222222221, 25.5625],
      [50.31250000000001, 40.3125],
      [64.72222222222223, 48.8125],
      [10.034722222222223, 57.8125],
      [57.8125, 65.1875],
      [37.88194444444444, 85.8125]
    ]

var containerLocationsPercent = 
    [
     [2.361111111111111, 15.0625],
     [28.958333333333336, 6.3125],
     [60.90277777777777, 4.0625],
     [70.97222222222223, 25.0625],
     [51.66666666666667, 32.125],
     [73.05555555555556, 48.25],
     [8.88888888888889, 56.00000000000001],
     [61.979166666666664, 72.125],
     [8.402777777777779, 79]
    ];



