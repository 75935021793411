import React, {Component} from 'react';
import { CSSTransition } from 'react-transition-group' 
import ReactDOM from 'react-dom';
import { TitleBlock, LeftBlock, RightBlock, ImageBlock, DoubleImageBlock, DoubleTextBlock, VideoBlock, MobileTextBlock, MobileImageBlock} from '../blocks/Blocks.js'
import {ProjectFooter} from './ProjectFooter'
import {Footer} from '../general/Footer.js'
import {slugify, checkIsIE} from '../../lib/utils.js'


import get from 'lodash/get'

export class ProjectPage extends Component {
	constructor() {
		super();

    this.state = {
      projects: {},
      activeProject: null,
      transitioning: false,
      pastInitialLoad: false,
      mobileConfig: window.innerWidth < 1000,
      numTextBlocks: 0
    };
    // this.mobileWidth = 1000;
	}

  componentDidMount() {
    this.findNumTextBlocks();
    this.preloadImages();
    this.preloadNextAndPrev();
  }


	componentWillMount() {
    // const URL = 'http://ec2-54-202-92-145.us-west-2.compute.amazonaws.com/api/data.json';
    // var checkIE = checkIsIE();
    // if (checkIE) {
    //   var URL = 'http://ec2-54-202-92-145.us-west-2.compute.amazonaws.com/api/data.json';
    // } else {
    //   var URL = 'https://d355psrnhl58dk.cloudfront.net/api/data.json';
    // }



    // var that = this;
    // let resp = {};
    
    // if (window.innerWidth < this.mobileWidth) {
    //   this.setState({
    //     mobileConfig: true
    //   })
    // };

    // fetch(URL)
    //   .then((data) => data.json())
    //   .then((json) => {
    //     json.data.forEach(function(item, idx) {
    //       let numText = item.numTextBlocks;
    //       var currentText = 0;
          
    //       item.next = json.data[idx + 1] ? slugify(json.data[idx + 1].title) : slugify(json.data[0].title);
    //       item.prev = json.data[idx - 1] ? slugify(json.data[idx - 1].title) : slugify(json.data[json.data.length - 1].title);

    //       item.blocks.forEach(function(block) {
    //         if (block.type === "imageText" || block.type === "textImage") {
    //           currentText++;
    //           if (currentText === numText) {
    //             block.isLast = true;
    //           }
    //         }
    //       })

    //       resp[slugify(item.title)] = item
          
    //     });
    //     var name = this.props.match.params.slug;
    //     that.setState({projects: resp, activeProject: resp[name]});

    //     this.preloadImages();
    //     this.preloadNextAndPrev();
    // });
      // this.preloadImages();
      // this.preloadNextAndPrev();

	}

  componentWillReceiveProps(nextProps) {
    let slug = nextProps.match.params.slug;
    this.setState({activeProject: this.state.projects[slug], transitioning: true, pastInitialLoad: true})
    let that = this;
    setTimeout(function() {
      that.setState({transitioning: false});
    }.bind(this), 1200)

    setTimeout(() => {
      this.findNumTextBlocks()
      this.preloadNextAndPrev();
    }, 500);

  }

  componentDidUpdate() {
    if (this.state.transitioning) {
      ReactDOM.findDOMNode(this).scrollTop = 0
      var that = this;
      setTimeout(function() {
      }, 300)
    }
  }

  preloadImages() {
    const activeProjectData = this.props.projects[this.props.match.params.slug];

    for (let key in this.props.projects) {
      var project = this.props.projects[key];
      if (project.fields.titleImage) {
        let img = new Image()

        img.src =  `${project.fields.titleImage.fields.file.url}?w=1800&fm=jpg&q=90`;
      }
    }
  }

  preloadNextAndPrev() {
     
    const activeProjectData = this.props.projects[this.props.match.params.slug];

    var next = this.props.projects[activeProjectData.next];
    var prev = this.props.projects[activeProjectData.prev];

    if (!next || !prev) return
    //technically already covered by the preload images func...
    //although this would prioritize the next and prev buttons...    

    const nextContent = next.fields.projectContent;
    const prevContent = prev.fields.projectContent;

    for (var i = 0; i < nextContent.length; i++) {
      const type = nextContent[i].fields.type;

      if (type === "Text-Image" ||
          type === "Image-Text") {

        let img = new Image()
        img.src = `${nextContent[i].fields.images[0].fields.file.url}?w=1000&h=800&fm=jpg&q=80&fit=fill`

      } else if (type === "Image-Image") {

        let imgOne = new Image();
        imgOne.src = `${nextContent[i].fields.images[0].fields.file.url}?w=1000&h=800&fm=jpg&q=80&fit=fill`

        let imgTwo = new Image()
        imgOne.src = `${nextContent[i].fields.images[1].fields.file.url}?w=1000&h=800&fm=jpg&q=80&fit=fill`

      } else if (type === "Full Image") {

        let img = new Image()
        img.src = `${nextContent[i].fields.images[0].fields.file.url}?w=1800&q=80`

      }

    }

    for (i = 0; i < prevContent.length; i++) {
      const type = prevContent[i].fields.type;

      if (type === "Text-Image" ||
          type === "Image-Text") {

        let img = new Image()
        img.src = `${prevContent[i].fields.images[0].fields.file.url}?w=1000&h=800&fm=jpg&q=80&fit=fill`

      } else if (type === "Image-Image") {

        let imgOne = new Image();
        imgOne.src = `${prevContent[i].fields.images[0].fields.file.url}?w=1000&h=800&fm=jpg&q=80&fit=fill`;

        let imgTwo = new Image()
        imgOne.src = `${prevContent[i].fields.images[1].fields.file.url}?w=1000&h=800&fm=jpg&q=80&fit=fill`;

      } else if (type === "Full Image") {

        let img = new Image()
        img.src = `${prevContent[i].fields.images[0].fields.file.url}?w=1800&q=80`

      }
    }
  }

  sortMobileBlocks(blocks) {
    let textBlocks = [];
    let otherBlocks = [];
    var count = 0;
    var isFirst;
    let that = this;

    blocks.forEach(function(block, idx) {
      const type = block.fields.type;
      if (type === "Image-Text" || type === "Text-Image") {
        if (count === 0) {
          isFirst = true;
        } else {
          isFirst = false;
        }

        let isLast = idx === that.state.numTextBlocks ? true : false;
        textBlocks.push({text: block.fields.text, type: "mobileText", isLast: isLast, isFirst: isFirst});
        otherBlocks.push({image: block.fields.image, type: "mobileImage"});
        count++
      } else if (block.type === "textText") {

        textBlocks.push({text: block.fields.textLeft, type: "mobileText"});
        textBlocks.push({text: block.fields.textRight, type: "mobileText"});
      } else {
        otherBlocks.push(block)
      }
    })
    return textBlocks.concat(otherBlocks)
  }

  findNumTextBlocks() {
    const activeProjectData =this.props.projects[this.props.match.params.slug];
    let blocks = get(activeProjectData, "fields.projectContent", []);
    let currIdx = 0;
    blocks.forEach((item,idx) => {
      const type = item.fields.type;
      if (type === "Image-Text" || type === "Text-Image" || type === "Text-Text") {
        currIdx = idx;
      }
    });
    
    this.setState({
      numTextBlocks: currIdx
    })
  }

	render() {

		let projectClasses = ["project-page", "is-active"];


    const activeProjectData = this.props.projects[this.props.match.params.slug];
    let imageSrc = get(activeProjectData, "fields.titleImage.fields.file.url", "")
    let title  = get(activeProjectData, "fields.title", "")
    let blocks = get(activeProjectData, "fields.projectContent", []);


    let next =  activeProjectData && activeProjectData.next;
    let prev = activeProjectData && activeProjectData.prev;
    
    let photographer = activeProjectData.fields.photographer || ""

    var credits = {};


      
    credits['year'] = get(activeProjectData, "fields.year", "")
    credits['materials'] = get(activeProjectData, "fields.materials", "")
    credits['client'] = get(activeProjectData, "fields.client", "")
    credits['clientHyperlink'] = get(activeProjectData, "fields.clientHyperlink", "")
    
    if (this.state.transitioning) {
      projectClasses.push("next-project")
    }

    if (this.state.pastInitialLoad) {
      projectClasses.push("past-initial-load")
    }

    if (checkIsIE()) {
      projectClasses.push("is-ie");
    }
    
    if (!this.state.mobileConfig) {
      return (
        <div className={projectClasses.join(" ")}>

          <div className="loading load-page"></div>
          

           <TitleBlock title={title} imageSrc={`${imageSrc}?w=1800&fm=jpg&q=90`}></TitleBlock>

          {blocks.map((item, i) => <BlockWrapper isLast={i === this.state.numTextBlocks} key={i} item={item} credits={credits}></BlockWrapper>)}
          <ProjectFooter next={next} prev={prev} photographer={photographer}></ProjectFooter>
          <Footer></Footer>
        </div>
      )
    } else {
      let sortedBlocks = this.sortMobileBlocks(blocks);
      return (
        <div className={projectClasses.join(" ")}>

          <div className="loading load-page"></div>
          
          <TitleBlock title={title} imageSrc={`${imageSrc}?w=1800&fm=jpg&q=90`}></TitleBlock>
          
          {sortedBlocks.map((item, i) => <BlockWrapper key={i} item={item} credits={credits}></BlockWrapper>)}
          <ProjectFooter next={next} prev={prev} photographer={photographer}></ProjectFooter>
          <Footer></Footer>
        </div>
      )
    }

	}
}

/*
<CSSTransition
  transitionName="project-transition"
  transitionAppear={true}
  transitionAppearTimeout={3000}
  transitionEnterTimeout={3000}
  transitionLeaveTimeout={600}>
 <TitleBlock title={title} imageSrc={`${imageSrc}?w=1800&fm=jpg&q=90`}></TitleBlock>
</CSSTransition>
*/



const BlockWrapper = (props) => {
  let type = props.item.type || props.item.fields.type
  let renderCredits = props.isLast || props.item.isLast ? true : false;

  let image, text;
  switch(type) {
    // case "Text-Text": 
    //   return (
    //     <DoubleTextBlock textLeft={props.item.textLeft} textRight={props.item.textRight}></DoubleTextBlock>
    //   )
      
    case "Full Image":
      image = get(props.item, "fields.images[0].fields.file.url", "");

      return (
        <ImageBlock imageSrc={image}></ImageBlock>
      )
      
    case "Image-Text":
      image = get(props.item, "fields.images[0].fields.file.url", "");
      text = get(props.item, "fields.text", "");

      return (
        <LeftBlock imageSrc={image} text={text} renderCredits={renderCredits} credits={props.credits}></LeftBlock>
      )
      
    case "Text-Image":

      image = get(props.item, "fields.images[0].fields.file.url", "");
      text = get(props.item, "fields.text", "");

      return (
        <RightBlock imageSrc={image} text={text} renderCredits={renderCredits} credits={props.credits}></RightBlock>
      )
      
    case "Full Video":
      const video =  get(props.item, "fields.videoSource", "");
      console.log(video, props.item)
      return (
        <VideoBlock video={video}></VideoBlock>
      )

      
    case "Image-Image":
      const imageLeft = get(props.item, "fields.images[0].fields.file.url", "");
      const imageRight = get(props.item, "fields.images[1].fields.file.url", "");

      return (
        <DoubleImageBlock imageLeft={imageLeft} imageRight={imageRight}></DoubleImageBlock>
      )
    
    case "mobileText":
      return (
        <MobileTextBlock text={props.item.text} renderCredits={renderCredits} credits={props.credits} isFirst={props.item.isFirst} isLast={props.item.isLast}></MobileTextBlock>
      )

    case "mobileImage":
      return (
        <MobileImageBlock image={props.item.image}></MobileImageBlock>
      )

    default:
      return (
          <h1>nothing here</h1>
      )
      
  }
}
