import React from 'react'
import {Link} from 'react-router-dom';

export const ProjectFooter = (props) => {
			if (props.photographer) {
			return(
					<div className="with-photographer group footer-section">
						<div className="photographer-wrapper">
							<p className="photographer-credits">Photography: {props.photographer}</p>
						</div>
						<Link to={"/work/" + props.prev}><h1 className="nav-button prev">Prev Project</h1></Link>
						<Link to={"/work/" + props.next}><h1 className="nav-button next">Next Project</h1></Link>
					</div>
				)
			} else {
				return(
					<div className="group footer-section">
						<Link to={"/work/" + props.prev}><h1 className="nav-button prev">Prev Project</h1></Link>
						<Link to={"/work/" + props.next}><h1 className="nav-button next">Next Project</h1></Link>
					</div>
				)
			}
	}
