import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { HomePage } from './components/homepage'
import {ProjectPage} from './components/projectpage'
import { AboutPage } from './components/aboutpage'
import { PressPage } from './components/presspage'

import {Header} from './components/general/Header.js'

import {slugify} from './lib/utils'

import './App.css';

const contentful = require("contentful");
const contentfulClient = contentful.createClient({
  accessToken:'uEWUGOUnhN_t9nr7v8saj3bJR_DimDxauaJiYLDq5eo',
  space: 'b1if2gbzt1pj',
  removeUnresolved: true
});


class Visibility extends Component {

  constructor() {
    super();
    this.projects = null;

    this.state = {
      ready: false,
      projects: {},
      homePageFeaturedItems: [],
      activeProject: {
        id: null,
        image: undefined,
        name: undefined
      },
      // infoVisible: false,
      // aboutVisible: false,
      // projectVisible: false,
      // menuActive: true,
      // menuHref: "/info",
      // ready: false,
    };

    // this.w = window.innerWidth; 
    // this.h = window.innerHeight;


    // this.toggleMenu = this.toggleMenu.bind(this)
    // this.updateActiveProject = this.updateActiveProject.bind(this)
  }

  componentDidMount() {
    this._loadSiteData()
  }

  _loadSiteData() {
    Promise.all([this._loadProjects(), this._loadHomePage(), this._loadAboutPage(), this._loadPressPage()]).then(() => {
      this.setState({
        ready: true
      })
    })
  }

  _loadProjects() {
    const params = {
      content_type: "project",
      select: "fields,sys.id",
      include: 3,
      order: "-fields.date"
    }

    return contentfulClient.getEntries(params)
    .then(resp => {
      const data = {};
      const products = [], 
            furniture = [],
            lighting = [],
            spaces = [];


      resp.items.forEach((item, idx) => {
        switch(item.fields.projectType) {
          case "Product":
            products.push(item);
            break;
          case "Furniture":
            furniture.push(item);
            break;
          case "Lighting":
            lighting.push(item);
            break;
          case "Space":
            spaces.push(item);
            break;
        }
      });

      const orderedItems = products.concat(furniture).concat(lighting).concat(spaces);

      orderedItems.forEach((item, idx) => {
        data[item.fields.slug] = item;
        const nextItem = orderedItems[idx + 1] || orderedItems[0];
        const prevItem = orderedItems[idx - 1] || orderedItems[orderedItems.length-1];
        
        data[item.fields.slug].next = nextItem.fields.slug;
        data[item.fields.slug].prev = prevItem.fields.slug;
      })

      return data;
    }).then(data => {
      this.setState({
        projects: data
      });

    })
  }

  _loadHomePage() {

    return contentfulClient.getEntry("3gyFrU9EDWL1WzIfU5BF7Q")
      .then(resp => {
        this.setState({
          homePageFeaturedItems: resp.fields.homePageFeaturedItems
        });
      })

  }

  _loadAboutPage() {

    return contentfulClient.getEntry("5zwh1XeDXVyFa2VlNKbaSx")
      .then(resp => {
        this.setState({
          aboutPage: resp.fields
        });
      })

  }

  _loadPressPage() {

    return contentfulClient.getEntry("1ElOPRTC0ku6lH5Dq01QRO")
      .then(resp => {
        this.setState({
          pressPage: resp.fields
        });
      })

  }

  

  
  _renderHomePage(props) {
    return <HomePage projects={this.state.projects} homePageFeaturedItems={this.state.homePageFeaturedItems} {...props}/>
  }

  _renderProjectPage(props) {
    return <ProjectPage projects={this.state.projects} {...props} />
  }

  _renderAboutPage(props) {
    return <AboutPage data={this.state.aboutPage} {...props}/>
  }

  _renderPressPage(props) {
    return <PressPage data={this.state.pressPage} {...props} />
  }

  render() {
     if (!this.state.projects || !this.state.ready) return null;

      return (
        <Router>
          <div className="container">
            <Route path="/"  component={Header}></Route>
            <Route path="/"  render={this._renderHomePage.bind(this)}></Route>
            <Route path="/info" component={this._renderAboutPage.bind(this)}></Route>
            <Route path="/press"  component={this._renderPressPage.bind(this)}></Route>
            <Route path="/work/:slug"  render={this._renderProjectPage.bind(this)}></Route>
          </div>
        </Router>
      )
  }
}


export default Visibility