import React, {Component, PureComponent} from 'react';

export class Scrollbar extends PureComponent  {
	constructor(props) {
		super(props);
	}

	
	render() {
		let offset = (this.props.deltaY / (this.props.gridHeight + this.props.height)) * (this.props.height - 104);
		let style = {
			transform: "translate3d(0, "+offset+"px, 0)"
		}
		let showWork = this.props.showWork ? "" : "hidden"
		return (
			<div className={"scrollbar " + showWork} onMouseDown={this.props.onMouseDown} style={style} ></div>
		)
	}

}
	