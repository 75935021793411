import React from 'react';
import {Link} from 'react-router-dom';

export const LeftBlock = (props) => {
	
	let styles = {
		backgroundImage: "url("+props.imageSrc+"?w=1000&h=800&fm=jpg&q=85&fit=fill)"
	}

	return(
		<div className="block group">
			<div className="half left image-block" style={styles}></div>
			<div className="half right text-block"><div className="text-wrap"><p className="project-description" dangerouslySetInnerHTML={{__html: props.text}}></p> {props.renderCredits ? <CreditSection credits={props.credits}></CreditSection> : ''}</div></div>
		</div>
	)
}


export const RightBlock = (props) => {
	let styles = {
		backgroundImage: "url("+props.imageSrc+"?w=1000&h=800&fm=jpg&q=85&fit=fill)"
	}
	let credits = props.renderCredits ? "credits" : ""

	return(
		<div className="block group">
			<div className={"half left text-block " + credits}><div className="text-wrap"><p className="project-description" dangerouslySetInnerHTML={{__html: props.text}}></p> {props.renderCredits ? <CreditSection credits={props.credits}></CreditSection> : ''}</div></div>
			<div className={"half right image-block " + credits} style={styles}></div>
		
		</div>
	)
}

export const ImageBlock = (props) => {
	let styles = {
		backgroundImage: "url("+props.imageSrc+"?w=1800&q=90)"
	}

	return(
		<div className={`block group full image-block ${props.className}`}style={styles}>
		</div>
	)

}

export const TitleBlock = (props) => {

	// if (!)
	let styles = {
		backgroundImage: "url("+props.imageSrc+")"
	}
	var splitText = props.title.split(" ");
	var len = props.title.length;
	switch(splitText.length) {
		case 1:
			return(
				<div key={'p-123'} className="block group full title image-block" style={styles}>
						<h1 key="h-1324" className="project-title">{props.title}</h1>
				</div>
			)
			break;
		case 2:
			if (len > 17) {
				return(
					<div key={'p-123'} className="block group full title image-block" style={styles}>
						<h1 key="h-1324" className="project-title two-lines">{splitText[0]} <br></br> {splitText[1]}</h1>
					</div>
				)
			} else {
				return(
					<div key={'p-123'} className="block group full title image-block" style={styles}>
							<h1 key="h-1324" className="project-title">{props.title}</h1>
					</div>
				)
			}
			break;
		case 3:
			return(
					<div key={'p-123'} className="block group full title image-block" style={styles}>
						<h1 key="h-1324" className="project-title two-lines">{splitText[0] + " " +splitText[1]} <br></br> {splitText[2]}</h1>
					</div>
				)
			break;
		case 4:
				return(
					<div key={'p-123'} className="block group full title image-block" style={styles}>
						<h1 key="h-1324" className="project-title two-lines">{splitText[0] + " " +splitText[1]} <br></br> {splitText[2] + " " +splitText[3]}</h1>
					</div>
				)
			break;
		default:
			return(
				<div key={'p-123'} className="block group full title image-block" style={styles}>
						<h1 key="h-1324" className="project-title">{props.title}</h1>
				</div>
			)
	}
}

export const VideoBlock = (props) => {
	console.log(props.video)
	return (


		<div className="block group full video-block">
			<div className="video-wrapper">
				<iframe src={props.video} frameBorder="0"></iframe>
			</div>
		</div>
		)

}

export const DoubleImageBlock = (props) => {
	let stylesLeft = {
		backgroundImage: "url("+props.imageLeft+"?w=1000&h=800&fm=jpg&q=80&fit=fill)"
	}
	let stylesRight = {
		backgroundImage: "url("+props.imageRight+"?w=1000&h=800&fm=jpg&q=80&fit=fill)"
	}
	return (
		<div className="block group">
			<div className="half left image-block" style={stylesLeft}>
			</div>
			<div className="half right image-block" style={stylesRight}>
			</div>
		</div>
	)
}



export const DoubleTextBlock = (props) => (
		<div className="block group">
			<div className="half left text-block">
				<p className="project-description" dangerouslySetInnerHTML={{__html: props.textLeft}}></p>
			</div>
			<div className="half right text-block">
				<p className="project-description" dangerouslySetInnerHTML={{__html: props.textRight}}></p>
			</div>
		</div>
)

export const MobileTextBlock = (props) => {
	let firstClass = props.isFirst ? "first " : ""
	let lastClass = props.isLast ? "last " : ""
	return(
		<div className={firstClass + lastClass + "half text-block mobile-text-block"}>
			<div className="text-wrap">
				<p className="project-description" dangerouslySetInnerHTML={{__html: props.text}}></p> 
				{props.renderCredits ? <CreditSection credits={props.credits}></CreditSection> : ''}
			</div>
		</div>
	)

}


export const MobileImageBlock = (props) => {
	if (!props.image) return null
		
	let styles = {
		backgroundImage: "url("+props.image+")"
	}
	return(
		<div className="block group">
			<div className="half image-block" style={styles}>
			</div>
		</div>
	)
}

const CreditSection = (props) => {
	if (props.credits.client) {
		if (props.credits.clientHyperlink) {
			return (
				<div className="credit-section">
					<h3 className="credit">Client: <a href={props.credits.clientHyperlink} target="_blank">{props.credits.client}</a></h3>
					{props.credits.materials ? <h3 className="credit">Materials: {props.credits.materials}</h3> : null }
					<h3 className="credit">Year: {props.credits.year}</h3>
				</div>
			)
		} else {
			return (
				<div className="credit-section">
					<h3 className="credit">Client: {props.credits.client}</h3>
					{props.credits.materials ? <h3 className="credit">Materials: {props.credits.materials}</h3> : null }
					<h3 className="credit">Year: {props.credits.year}</h3>
				</div>
			)
		}
	} else {
		return (
			<div className="credit-section">
					{props.credits.materials ? <h3 className="credit">Materials: {props.credits.materials}</h3> : null }
				<h3 className="credit">Year: {props.credits.year}</h3>
			</div>
		)
	}
}




