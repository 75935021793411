import React, {Component, PureComponent}  from 'react'


export class ImageRows extends PureComponent {
	constructor(props) {
		super(props);
	}
	
	render() {
		let productRows = Array(Math.ceil(this.props.numProducts / 4)).fill().map((item, i) => <ProjectRow numToSubtract={((i + 1) * 4) - this.props.numProducts}  key={'pr'+i} ref={'pr' + i} rowNum={i} imageHeights={this.props.productHeights} sectionName="products"></ProjectRow>)
		let furnitureRows = Array(Math.ceil(this.props.numFurniture / 4)).fill().map((item, i) => <ProjectRow numToSubtract={((i + 1) * 4) - this.props.numFurniture}  key={'fu'+i} ref={'fu' + i} rowNum={i} imageHeights={this.props.furnitureHeights} sectionName="furniture"></ProjectRow>)
		let spaceRows = Array(Math.ceil(this.props.numSpaces / 4)).fill().map((item, i) => <ProjectRow numToSubtract={((i + 1) * 4) - this.props.numSpaces}  key={'sp'+i} ref={'sp' + i} rowNum={i} imageHeights={this.props.spaceHeights} sectionName="spaces"></ProjectRow>)
		let lightingRows = Array(Math.ceil(this.props.numLighting / 4)).fill().map((item, i) => <ProjectRow numToSubtract={((i + 1) * 4) - this.props.numLighting}  key={'li'+i} ref={'li' + i} rowNum={i} imageHeights={this.props.lightingHeights} sectionName="lighting"></ProjectRow>)

		let allRows = productRows.concat(furnitureRows).concat(lightingRows).concat(spaceRows)

		return (
			<div className="grid-view group">
				{allRows}
			</div>
		)
	}
}

export class ProjectRow extends PureComponent {
	constructor(props) {
		super(props);
	}

	render() {

		let height = getMax(this.props.imageHeights, this.props.rowNum);
		if (!height) {
			height = 350
		}
		let numElements = this.props.numToSubtract > 0 ? 4 - this.props.numToSubtract : 4;
		let imagePositions = Array(numElements).fill().map((item, i) => <ProjectPosition key={"ipr" + i} height={height} ipRef={el => this['ip' + i] = el}></ProjectPosition>)
		let isNewSection = this.props.rowNum === 0 ? "new-section" : ""

		return (
			<div className={this.props.sectionName + " project-row group float-center " + isNewSection}>
				<div className="child group">
					{imagePositions}
				</div>
			</div>
		)
	}

}

class ProjectPosition extends PureComponent {
		constructor(props) {
			super(props);
		}
		
		render() {
			let styles = {
				height: this.props.height
			}
			return(
				<div style={styles} className="project-position" ref={this.props.ipRef}></div>
			)
		}
}

function getMaxAlt(arr, rowNum) {
	var max = 0;
	var numPerRow=4;
	var start = numPerRow * rowNum;
	var end = start + numPerRow
	for (var i = start; i < end; i++) {
		if (arr[i].h > max) {
			max = arr[i].h;
		}
	}
	return max
}


function getMax(arr, rowNum) {
	var max = 0;
	var numPerRow=4;
	var start = numPerRow * rowNum;
	var end = start + numPerRow
	for (var i = start; i < end; i++) {
		if (arr[i] > max) {
			max = arr[i];
		}
	}
	return max
}