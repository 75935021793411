import React from 'react';

export const Footer = (props) => {
  let d = new Date();

  let year = d.getFullYear();
	return(
		<div className="footer">
			<h1 className="copyright">&copy; Guerra Office {year}</h1>
		</div>
		)
}
	