import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { LeftBlock, RightBlock, ImageBlock, DoubleImageBlock, DoubleTextBlock, VideoBlock } from '../blocks/Blocks.js'
import {Footer} from "../general/Footer.js"
import { CSSTransition } from 'react-transition-group' 
import {checkIsIE} from '../../lib/utils.js'

export class AboutPage extends Component {
	constructor(props) {
		super(props);
		// var checkIE = checkIsIE()
	 //    if (checkIE) {
	 //      var URL = 'http://ec2-54-202-92-145.us-west-2.compute.amazonaws.com/api/about.json';
	 //    } else {
	 //      var URL = 'https://d355psrnhl58dk.cloudfront.net/api/about.json';
	 //    }


	    // var that = this;
	    // fetch(URL)
	    //   .then((data) => data.json())
	    //   .then((json) => {
	    //   	// console.log(json.data[0].awardList)
	    //   	// that.setState({
			  //   	// clientList: json.data[0].clientList,
			  //   	// awardList: json.data[0].awardList,
			  //   	// contactEmail: json.data[0].contactEmail,
			  //   	// descriptionMain: json.data[0].descriptionMain,
			  //   	// descriptionSub: json.data[0].descriptionSub,
			  //   	// headerImage: json.data[0].headerImage,
			  //   	// id: json.data[0].id,
			  //   	// infoImages: json.data[0].infoImages,
			  //   	// video: json.data[0].video,
			  //   	// internshipEmail: json.data[0].internshipEmail
	    //   	// })
	    // });

	    this.state = {
	    	clientList: [],
	    	contactEmail: null,
	    	descriptionMain: null,
	    	descriptionSub: null,
	    	headerImage: null,
	    	id: null,
	    	infoImages: null,
	    	internshipEmail: null,
	    	mailChimpEmail: "http://vsby.us3.list-manage1.com/subscribe/post?u=12a4b4959655121b42e6a840b&id=20ea9a9a3f"
	    }
	}
	

	render() {
	 	const pageClasses = ["about-page"]
		const pageActive = this.props.visible ? "is-active"  : ""
		pageClasses.push(pageActive)
		let prefix = "https://d355psrnhl58dk.cloudfront.net/"
		if (checkIsIE()) {
			// var headerImage = "http://ec2-54-202-92-145.us-west-2.compute.amazonaws.com/" + this.state.headerImage
		} else {
			// var headerImage = prefix + this.state.headerImage
		}

		let imageOne = this.state.infoImages ? prefix + this.state.infoImages[0] : ""
		let imageTwo = this.state.infoImages ? prefix + this.state.infoImages[1] : ""
		let imageThree = this.state.infoImages ? prefix + this.state.infoImages[2] : ""
		let imageFour= this.state.infoImages ? prefix + this.state.infoImages[3] : ""
		// let videoSrc= this.state.video ? prefix + this.state.video: null;

		const {awardList, pressEmail, clientList, contactEmail, descriptionMain, descriptionSub, headerImage, infoImages, internshipEmail, videoSrc} = this.props.data;

		return (
			<div className={pageClasses.join(' ')}>
				<div className="loading load-page"></div>

		         <ImageBlock imageSrc={`${headerImage.fields.file.url}?fm=jpg&w=1800&q=80`} className="about-head-image"></ImageBlock>
				
				<DescriptionBlock descriptionMain={descriptionMain} descriptionSub={descriptionSub} imageSrc={`${infoImages[0].fields.file.url}?fm=jpg&w=900&q=80`}></DescriptionBlock>
				<ClientBlock clients={clientList} imageSrc={`${infoImages[1].fields.file.url}?fm=jpg&w=900&q=80`}></ClientBlock>
				{videoSrc ? <VideoBlock video={videoSrc}></VideoBlock> : null}
				<AwardBlock awards={awardList} imageSrc={`${infoImages[2].fields.file.url}?fm=jpg&w=900&q=80`}></AwardBlock>
				<ContactBlock mailChimpEmail={this.state.mailChimpEmail} contactEmail={contactEmail} internshipEmail={internshipEmail} pressEmail={pressEmail} imageSrc={`${infoImages[3].fields.file.url}?fm=jpg&w=900&q=80`}></ContactBlock>
			</div>
		)
	}
}
/*
<CSSTransition
  transitionName="project-transition"
  transitionAppear={true}
  transitionAppearTimeout={3000}
  transitionEnterTimeout={3000}
  transitionLeaveTimeout={600}>
 <ImageBlock imageSrc={`${headerImage.fields.file.url}?fm=jpg&w=1800&q=80`} className="about-head-image"></ImageBlock>
</CSSTransition>
*/

const DescriptionBlock = (props) => {
	
	let styles = {
		backgroundImage: "url("+props.imageSrc+")"
	}
	function createMarkup() {
	  return {__html: props.descriptionSub};
	}
	return(
		<div className="block group">
			<div className="half left text-block"><p className="description-main" dangerouslySetInnerHTML={{__html: props.descriptionMain}}></p> <p className="description-sub" dangerouslySetInnerHTML={createMarkup()}></p></div>
			<div className="half right image-block" style={styles}></div>
		</div>
	)

}

const ClientBlock = (props) => {
	let styles = {
		backgroundImage: "url("+props.imageSrc+")"
	}

	let length = Math.ceil(props.clients.length / 2);
	let firstHalf = props.clients.slice(0, length)
	let secondHalf = props.clients.slice(length)

	return(
		<div className="block group">
			<div className="half left image-block" style={styles}></div>

			<div className="half right text-block">
				<div className="client-wrap">
					<h6 className="small-header">Clients:</h6>
					<div className="clients-list clients-left">{firstHalf.map((item, idx)=> <a href={item.fields.url} key={"cl1" + idx} target="_blank"><h1 key={"l" + idx } className="client">{item.fields.title}</h1></a>)}</div>
					<div className="clients-list clients-right">{secondHalf.map((item, idx)=> <a href={item.fields.url} key={"cl2" + idx} target="_blank"><h1 key={"r" + idx } className="client">{item.fields.title}</h1></a>)}</div>
				</div>
			</div>
		</div>
	)
}

const AwardBlock = (props) => {
	let styles = {
		backgroundImage: "url("+props.imageSrc+")"
	}

	let firstHalf = props.awards ? props.awards : []

	return(
		<div className="block group">
			<div className="half left text-block">
				<div className="client-wrap">
					<h6 className="small-header">Awards & Honors:</h6>
					<div className="clients-list clients-left awards-list">{firstHalf.map((item, idx)=> <a href={item.fields.url} key={"aw2" + idx} target="_blank"><h1 key={"a" + idx } className="client">{item.fields.title}</h1></a>)}</div>
				</div>
			</div>
			<div className="half right image-block" style={styles}></div>
		</div>
	)
}

const ContactBlock = (props) => {
	let styles = {
		backgroundImage: "url("+props.imageSrc+")"
	}

	return(
		<div className="block group">

			<div className="half left image-block" style={styles}></div>

			<div className="half right text-block">
				<div className="contact-section">
					<p className="small-header">Contact:</p>
					<a href={"mailto:" + props.contactEmail}><h1 className="email">{props.contactEmail}</h1></a>
				</div>

				<div className="contact-section">
					<p className="small-header">Press:</p>
					<a href={"mailto:" + props.pressEmail}><h1 className="email">{props.pressEmail}</h1></a>
				</div>


				<div className="contact-section">
					<p className="small-header">Internships:</p>
					<a href={"mailto:" + props.internshipEmail}><h1 className="email">{props.internshipEmail}</h1></a>
				</div>

				<MailChimp mailChimpEmail={props.mailChimpEmail}></MailChimp>				
			</div>
		</div>
	)

}

const MailChimp = (props) => {
	
	let style = {
		display: "none"
	};

	return(
		<div className="contact-section">

			<p className="small-header">Newsletter:</p>

			<form action={props.mailChimpEmail} name="mc-embedded-subscribe-form" target="_blank" method="post">
				<input className="form-text" name="EMAIL" type="text" placeholder="Your email"/> 
				<input type="text" style={style} name="b_12a4b4959655121b42e6a840b_20ea9a9a3f" value="" />
				<input type="submit" className="form-submit" value="Subscribe" name="subscribe" />
			</form>
			<Footer></Footer>
		</div>
	)
}

