import React, {Component, PureComponent} from 'react'


export class TextElement extends Component {
	constructor(props) {
		super(props);
		this.init = false;
		
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (!this.init) {
			if (nextProps.transform) {
				this.init = true;
			}
			return true
		}
		return this.props.showWork ? false : true
	}

	

	render() {

		let x = this.props.transform ? this.props.transform[0] : 0;
		let y = this.props.transform ? this.props.transform[1] : 0;
		let transform = "translate3d("+x+"px,"+y+"px,0)";
		let styles = { 
			transform: transform
		};
		

		return(
				<h1 style={styles} ref={this.props.textRef} className={"text-element te-" + this.props.className}>{this.props.word}</h1>
		) 
	}

}


