import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {slugify, checkIsIE} from '../../lib/utils.js'

export class PressPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			press: [],
			pressDocument: "",
			viewPress: false,
			foundDocument: true
		}
		this.pressDocuments = {};
		this.prefix = "https://d355psrnhl58dk.cloudfront.net/"
	}

	componentDidMount() {
		// var checkIE = checkIsIE()
		// if (checkIE) {
		// var URL = 'http://ec2-54-202-92-145.us-west-2.compute.amazonaws.com/api/press.json';
		// } else {
		// var URL = 'https://d355psrnhl58dk.cloudfront.net/api/press.json';
		// }
		this.setupPressObj()
		this.checkURL()

		// var that = this;
		// let resp = {};
		// fetch(URL)
		// .then((data) => data.json())
		// .then((json) => {
		// that.setState({press: json.data[0]})
		// 		that.checkURL()
		// });
	}

	documentHandler(e, data) {
		this.setState({
			pressDocument: data,
			viewPress: true,
			foundDocument: true
		})
	}

	closeDocumentView() {
		this.setState({
			viewPress: false
		})
	}
	
	setupPressObj() {
		var key;
		this.props.data.pressEntries.forEach((item) => {
			if (item.fields.document) {
				this.pressDocuments[slugify(item.fields.pressName + " " + item.fields.date)] = item.fields.document
			}
		});
		
		// for (key in this.props.data.pressE) {
		// 	var curr = this.state.press[key];
		// 	if (curr.document.length > 0) {
		// 	}
		// }
	}

	getParameterByName(name, url) {
	    if (!url) url = window.location.href;
	    name = name.replace(/[\[\]]/g, "\\$&");
	    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
	        results = regex.exec(url);
	    if (!results) return null;
	    if (!results[2]) return '';
	    return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	getandSetPressDocument(query) {
		var project = this.getParameterByName("view", query);
		var pressDoc = this.pressDocuments[project] ? this.pressDocuments[project] : null;

		if (pressDoc) {
			this.setState({
				pressDocument: pressDoc,
				viewPress: true,
				foundDocument: true
			})
		} else {
			this.setState({
				pressDocument: "pressDoc",
				viewPress: true,
				foundDocument: false
			})
		}
	}

	checkURL() {
		if (this.props.location.search.includes("view")) {
			this.getandSetPressDocument(this.props.location.search)
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.location.search.includes("view")) {
			this.getandSetPressDocument(nextProps.location.search)
		} else {
			this.setState({
				viewPress: false 
			})
		}

	}

	render() {
	let prefix = "https://d355psrnhl58dk.cloudfront.net/"
	const {pressEntries} = this.props.data;
	// date: "July 2019"
	// image: {sys: {…}, fields: {…}}
	// pressName: "The New York Times"
	// title: "The New York Times - July 2019"
	// url: "https://www.nytim
		return(
			<div className={this.state.viewPress ?  "show-modal press-page" : "press-page"}>
				<PressModal foundDocument={this.state.foundDocument} pressDocument={this.state.pressDocument}></PressModal>
  				{pressEntries.map((item, idx) => <PressSection key={"p" + idx} clickHandler={ this.documentHandler.bind(this) }document={item.document} href={item.fields.url} imgSrc={`${item.fields.image.fields.file.url}?fm=jpg&q=90&w=450`} name={item.fields.pressName} date={item.fields.date}></PressSection>)}
			</div>
		)
	}
}

const PressSection = (props) => {

	if (props.href) {
		return(
			<div className="press-section">
				<a href={props.href} target="_blank">
					<img className="press-image" src={props.imgSrc} alt=""/>
					<div className="classSection">
						<h5 className="press-title">{props.name}</h5>
						<h6 className="press-date">{props.date}</h6>
					</div>
				</a>
			</div>
			)
	} else {

		let viewLink = "?view=" + slugify(props.name + " " + props.date);

		return(
			<Link to={viewLink}>
				<div className="press-section">
					<img className="press-image" src={props.imgSrc} alt=""/>
					<div className="classSection">
						<h5 className="press-title">{props.name}</h5>
						<h6 className="press-date">{props.date}</h6>
					</div>
				</div>
			</Link>

		)

	}

}

const PressModal = (props) => {
	console.log(props.pressDocument)
	if (!props.pressDocument || !props.pressDocument.fields) return null

	return(
		<div className="press-modal">
			<p className={props.foundDocument ? "not-found hide" : "not-found"}>Sorry, couldn't find that image.</p>
			<img className={props.foundDocument ? "press-modal-image" : "press-modal-image hide"} src={`${props.pressDocument.fields.file.url}?w=1400&fm=jpg&q=90`} alt=""/>
		</div>
	)
}