import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';

if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}

export class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuOpen: false
		}
	}

	handleClick() {
		this.toggleMenu()
	}

	toggleMenu() {
		this.setState({menuOpen: !this.state.menuOpen})
	}

	componentWillReceiveProps(nextProps) {
		this.setState({menuOpen: false})
	}

	render() {
		let headerClasses = ["header", "group"];
		let isVisible = (this.props.location.pathname.includes("work/") || (this.props.location.pathname === "/press"  && this.props.location.search.includes("view"))) ? "onproject" : "";
		var link = "/";
		if (isVisible) {
			if (this.props.location.pathname === "/press"  && this.props.location.search.includes("view")) {
				link = "/press"
			} else if (this.props.location.pathname.includes("work/")) {
				link = "/work"
			}
		}
				
		headerClasses.push(isVisible)
		let navVisible = this.state.menuOpen ? "" : "hide" 
		let hamburgerClass = this.state.menuOpen ? "is-active" : ""

		return(
			<div className={headerClasses.join(" ")}>
				<Link to="/">
					<img className="logo" src="/images/go-logo.svg" alt="" />

					{/* <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.98 32.61"><title>visibility-logo</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M0,1.4H5.62L12.2,20,18.83,1.4h5.33L14.76,26.31H9.36Z"/><path d="M24.73,3a2.88,2.88,0,1,1,5.76,0,2.88,2.88,0,0,1-5.76,0ZM30,26.31H25.2V7.05H30Z"/><path d="M38.56,14c.86.25,1.66.4,2.63.68,3.09.94,4.61,2.45,4.61,5.94,0,3.92-3.24,6-7,6-5.33,0-7-3.13-6.87-6.48H36.5c0,1.37.47,2.59,2.34,2.59,1.26,0,2.27-.61,2.27-1.73s-.58-1.62-1.91-2a29.76,29.76,0,0,1-2.91-.86,5.5,5.5,0,0,1-4-5.69c0-3.24,2.74-5.62,6.59-5.62,4.07,0,6.48,2.05,6.52,6.08H41c0-1.48-.72-2.34-2.2-2.34-1.15,0-1.91.65-1.91,1.62S37.4,13.64,38.56,14Z"/><path d="M47.16,3a2.88,2.88,0,1,1,5.76,0,2.88,2.88,0,0,1-5.76,0Zm5.29,23.33H47.63V7.05h4.82Z"/><path d="M59.65,26.31H55.08V0H59.9V9a8.06,8.06,0,0,1,5.76-2.23c5.8,0,9.54,4,9.54,9.94,0,5.69-3.92,10-9.86,10a8.26,8.26,0,0,1-5.69-2.16Zm5.43-4c3.28,0,5.29-2.38,5.29-5.65S68.25,11,65.05,11s-5.29,2.38-5.29,5.65S61.88,22.32,65.09,22.32Z"/><path d="M76.64,3A2.88,2.88,0,1,1,82.4,3a2.88,2.88,0,0,1-5.76,0Zm5.29,23.33H77.11V7.05h4.82Z"/><path d="M89.39,26.31H84.56V0h4.82Z"/><path d="M91.55,3a2.88,2.88,0,1,1,5.76,0,2.88,2.88,0,0,1-5.76,0Zm5.29,23.33H92V7.05h4.82Z"/><path d="M105.12,7.05h3v4.07h-3v9c0,1.44.47,2.05,1.84,2.05h1.12v4.1a12.75,12.75,0,0,1-2.63.25c-3.64,0-5.15-1.84-5.15-5V11.12h-1.8V7.05h1.8V4h4.82Z"/><path d="M113.58,32.61l2.59-7.05-7.34-18.5h5.33l4.5,12,4.36-12h5l-9.72,25.56Z"/></g></g></svg> */}
				</Link>
				<div className={"nav group " +  navVisible}>
					<NavLink to="/work" activeClassName="active"><li className="first">Work</li></NavLink>
					<NavLink to="/press" activeClassName="active"><li>Press</li></NavLink>
					<NavLink to="/info" activeClassName="active"><li>Info</li></NavLink>
					<a href="https://www.instagram.com/guerra_office/"  target="_blank"><li>Process</li></a>
				</div>
				<Hamburger className={hamburgerClass} handleClick={this.handleClick.bind(this)}></Hamburger>
				<Link to={link}><Ex></Ex></Link>

			</div>
		)
	}

}

const Ex = (props) => (
    <div className="ex">
      <span className="one"></span>
      <span className="two"></span>
    </div>
  )


const Hamburger = (props) => (

	<button onClick={props.handleClick} className={"hamburger hamburger--collapse " + props.className} type="button">
  <span className="hamburger-box">
    <span className="hamburger-inner"></span>
  </span>
</button>
)