import React, {Component, PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {slugify} from '../../lib/utils.js'

export class ProjectElement extends Component {
	constructor(props) {
		super(props);
		this.centerX = 0;
		this.centerY = 0;
		this.imageWidth = 0;
		this.imageHeight = 0;
		this.map = {
			Small: .18,
			Medium: .2,
			Large: .22
		}
		this.state = {
			transitioning: false
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (!this.props.showWork && !this.props.isFeatured && !nextProps.showWork) {
			return false
		} else {
			return true
		}

		// return true
	}

	render() {
		var infoClass = ""
		var featureClass = ""
		if (this.props.showWork) {
			
			if (this.props.gridTransform) {
				vals = this.props.gridTransform;
			} else {
				var vals = {left: 0, top: 0};
			}

			var imageWidth;

			if (this.props.isFeatured) {
				imageWidth = this.props.imageWidth / 2;
			} else {
				featureClass = "opacity "
				imageWidth = this.props.w * this.map[this.props.imageSizing] / 2
			}

			var leftPosition = vals.left + (vals.width / 2) - imageWidth;
			var transform = "translate3d("+leftPosition+"px,"+(vals.top + this.props.deltaY)+"px,0)";
			infoClass = "show";

		} else {
			if (this.props.isFeatured) {
				var x = this.props.transform ? this.props.transform[0] : 0;
				var y = this.props.transform ? this.props.transform[1] : 0;
				var transform = "translate3d("+x+"px,"+y+"px,0)";
				
			} else {
				if (this.props.gridTransform) {
					vals = this.props.gridTransform;
				} else {
					var vals = {left: 0, top: 0};
				}
				let imageWidth = this.props.w * this.map[this.props.imageSizing] / 2
				var leftPosition = vals.left + (vals.width / 2) - imageWidth;
				var transform = "translate3d("+leftPosition+"px,"+(vals.top + this.props.deltaY)+"px,0)";
				featureClass = "hide opacity "
			}

		}

		// console.log()

		var map = {
			Small: "18vw",
			Medium: "20vw",
			Large: "22vw"
		}
		// console.log(this.props.imageSizing)
		let size = map[this.props.imageSizing];

		let styles = { 
			transform: transform
		};
		var imageStyles;
		if (!this.props.isFeatured) {
			imageStyles = {
				width: size
			};
		} else {
			imageStyles = {}
		}

		featureClass += this.props.isFeaturedMobile ? "mobile-featured mobile-featured-"+this.props.isFeaturedMobile : '' 
			
		const className = `${this.props.transitioning} project-element project-element-${this.props.className} ${featureClass} ${this.props.isFeatured ? `ft-${this.props.featuredNum}` : ""}`
		return(
			<Link to={"/work/" + slugify(this.props.projectTitle)}>
				<div style={styles}  className={className}>
					<FeaturedImage imageSizing={this.props.imageSizing} isFeatured={this.props.isFeatured} isFeaturedMobile={this.props.isFeaturedMobile} imageRef={this.props.imageRef} handleLoad={this.props.handleLoad} imageSrc={this.props.imageSrc} ></FeaturedImage>
					<ProjectTitle projectTitle={this.props.projectTitle} infoClass={this.props.showWork}></ProjectTitle>
					<ProjectInfo projectInfo={this.props.projectInfo} infoClass={this.props.showWork}></ProjectInfo>
				</div>
			</Link>
		)
	}
}



class FeaturedImage extends PureComponent {
	constructor(props) {
		super(props);
	}
	// shouldComponentUpdate(nextProps, nextState) {
	// 	return false
	// }

	render() {
		var map = {
			Small: "18vw",
			Medium: "20vw",
			Large: "22vw"
		}

		let size = map[this.props.imageSizing];
		var imageStyles;
		if (!this.props.isFeatured) {
			imageStyles = {
				width: size
			};
		} else {
			imageStyles = {
				width: size
			};
		};
		return(
			<img style={imageStyles} onLoad={this.props.handleLoad} ref={this.props.imageRef} src={this.props.imageSrc} alt=""/>
		)
	}

}


class ProjectTitle extends PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		let infoClass = this.props.infoClass ? "show" : ""
		return(
			<h5 className={"project-element-title " + infoClass}>{this.props.projectTitle}</h5>
		)
	}

}

class ProjectInfo extends PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		let infoClass = this.props.infoClass ? "show" : ""
		return(
			<h6 className={"project-element-info " + infoClass}>{this.props.projectInfo}</h6>
		)
	}

}



