import React, {Component, PureComponent} from 'react'
import ReactDOM from 'react-dom'

import {TextElement} from './TextElement'
import {ProjectElement} from './ProjectElement'
import {CanvasElement} from './CanvasElement'
import {ImageRows} from './Grid'
import {Scrollbar} from '../general/Scrollbar'
import {checkIsMobile, checkIsIE} from '../../lib/utils.js'

var FontFaceObserver = require('fontfaceobserver');

// // // // // // // // // // //  Start of the Container Code // // // // // // // // // // // 

var generator = new Simple1DNoise()

function ImageContainer(image,id,dx,dy, w, h, x,y, count) {
  
  var that = this;
  w = w || window.innerWidth;
  h = h || window.innerHeight;
  image.addEventListener("load", function() {
    rect = image.getBoundingClientRect()
    that.image = image
    that.x = x;
    that.y = y;
    that.w = rect.width;
    that.h = rect.height;
    that.leftBound = that.x;
    that.topBound = that.y;
    that.bottomBound = that.y + that.h;
    that.rightBound = that.x + that.w;
    that.centerX = that.leftBound + ((that.rightBound - that.leftBound) / 2);
    that.centerY = that.topBound + ((that.bottomBound - that.topBound) / 2);

    var initialValX = map(generator.getVal(dx), 0, 1, 0, w);
    
    var diffX = initialValX - that.x - that.w / 2;
    var initialValY = map(generator.getVal(dy), 0, 1, 0, h);

    if (diffX !== that.diffX && that.x !== 0 && that.y !== 0) {
      that.diffX = diffX
    }

    var diffY = initialValY - that.y - that.h / 2;

    if (diffY !== that.diffY && that.x !== 0 && that.y !== 0) {
      that.diffY = diffY
    }

  })

  var rect = image.getBoundingClientRect()

  this.x = x;
  this.y = y;
  this.w = rect.width;
  this.h = rect.height;
  this.leftBound = this.x;
  this.topBound = this.y;
  this.bottomBound = this.y + this.h;
  this.rightBound = this.x + this.w;
  this.centerX = this.leftBound + ((this.rightBound - this.leftBound) / 2);
  this.centerY = this.topBound + ((this.bottomBound - this.topBound) / 2);
  this.dx = dx;
  this.dy = dy;
  var initialValX = map(generator.getVal(dx), 0, 1, 0, w);
  
  var diffX = initialValX - this.x - this.w / 2;

  this.diffX = diffX;
  var initialValY = map(generator.getVal(dy), 0, 1, 0, h);
  var diffY = initialValY - this.y - this.h / 2;
  this.diffY = diffY;
  var test = map(generator.getVal(dy), 0, 1, 0, h);
}

ImageContainer.prototype.update = function(x,y) {
  this.leftBound = x;
  this.topBound =  y;
  this.bottomBound = y + this.h;
  this.rightBound = x + this.w;

  this.centerX = this.leftBound + ((this.rightBound - this.leftBound) / 2);
  this.centerY = this.topBound + ((this.bottomBound - this.topBound) / 2);
  
}


function Simple1DNoise() {
  var MAX_VERTICES = 256;
  var MAX_VERTICES_MASK = MAX_VERTICES -1;
  var amplitude = 1;
  var scale = 1;

  var r = [];

  for ( var i = 0; i < MAX_VERTICES; ++i ) {
      r.push(Math.random());
  }

  var getVal = function( x ){
      var scaledX = x * scale;
      var xFloor = Math.floor(scaledX);
      var t = scaledX - xFloor;
      var tRemapSmoothstep = t * t * ( 3 - 2 * t );

      var xMin = xFloor & MAX_VERTICES_MASK;
      var xMax = ( xMin + 1 ) & MAX_VERTICES_MASK;

      var y = lerp( r[ xMin ], r[ xMax ], tRemapSmoothstep );

      return y * amplitude;
    };

    var lerp = function(a, b, t ) {
        return a * ( 1 - t ) + b * t;
    };

    // return the API
    return {
        getVal: getVal,
        setAmplitude: function(newAmplitude) {
            amplitude = newAmplitude;
        },
        setScale: function(newScale) {
            scale = newScale;
        }
    };
};

function map(value, low1, high1, low2, high2) {
  return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}


// // // // // // //  Start of Text Container Code // // // // // // // // // 

  // these need to be added into the other situation
  var MAG = 4000;

  var containerLocations = [
    [34,120.5], // an
    [417, 50.5], // industrial
    [877, 32.5], //design 
    [1022, 200.5], //studio 
    [744,257], // that 
    [1052, 386], //designs 
    [128, 448], //objects 
    [892.5, 577], //spaces 
    [121, 632], //&spaces
  ];

  var containerSizes = [
    [220, 180], //w, h // an 
    [160, 300], //w, h // industrial
    [400, 100], //w, h // design
    [400, 120], //w, h // studio
    [110, 180], //w, h // that
    [360, 120], //w, h // designs
    [370, 120], //w, h // objects
    [400, 120], //w, h // furniture
    [500, 120] //w, h // and spaces
  ];

var containerLocationsPercent = 
    [
     [2.361111111111111, 15.0625],
     [28.958333333333336, 6.3125],
     [60.90277777777777, 4.0625],
     [70.97222222222223, 25.0625],
     [51.66666666666667, 32.125],
     [73.05555555555556, 48.25],
     [8.88888888888889, 56.00000000000001],
     [61.979166666666664, 72.125],
     [8.402777777777779, 79]
    ];

var containerLocationsMobile = 
    [
      [60.5775,64.7835],
      [222.352, 264.7816],
      [488.611, 452.188],
      [672.6, 323.123],
      [818.354, 441.47],
      [980.901, 222.391],
      [1213.5443,46.299],
      [1350.908, 190.488],
      [1625.376, 124.011]
    ];

var containerSizesPercent = 
    [ 
      [15.277777777777779, 22.5],
      [11.11111111111111, 37.5],
      [27.77777777777778, 12.5],
      [27.77777777777778, 15],
      [7.638888888888889, 22.5],
      [25, 15],
      [25.694444444444443, 15],
      [27.77777777777778, 15],
      [34.72222222222222, 15]
    ]

  var initialCoords = [
    [55,86.5],
    [406,86.5],
    [772.5,136.5],
    [1004, 204.5],
    [724.5,322.5],
    [932, 390.5],
    [144.5,462.5],
    [832.5,521.5],
    [545.5,686.5]
  ];
  
  var initialCoordsPercent =
    [
      [3.8194444444444446, 10.8125],
      [28.194444444444443, 10.8125],
      [53.645833333333336, 17.0625],
      [69.72222222222221, 25.5625],
      [50.31250000000001, 40.3125],
      [64.72222222222223, 48.8125],
      [10.034722222222223, 57.8125],
      [57.8125, 65.1875],
      [28.88194444444444, 85.8125]
    ];

  var initialCoordsPercentMobile =
    [
      [3.8194444444444446, 10.8125],
      [28.194444444444443, 10.8125],
      [73.645833333333336, 17.0625],
      [69.72222222222221, 25.5625],
      [50.31250000000001, 40.3125],
      [64.72222222222223, 48.8125],
      [10.034722222222223, 57.8125],
      [57.8125, 65.1875],
      [37.88194444444444, 85.8125]
    ];
    
  var containers = [], nodes = [];

  var containerHeight = 250, containerWidth = 380;
  var w = window.innerWidth, h = window.innerHeight;
  var distWidth, distHeight;


  function addNodes(x,y, w, h) {
    var numWidth, numHeight;
    distWidth = Math.floor(w / 2);
    distHeight = Math.floor(h / 2);

    var numWidth = Math.floor(w / distWidth);
    var numHeight = Math.floor(h / distHeight);
    var containerNodes = [];

    for (var i = 0; i <= numWidth - 0; i++ ) {
      for (var j = 0; j <= numHeight - 0; j++) {
        var newNode = new Node(x + i * distWidth, y + j * distHeight)
        // nodes.push(newNode)
        containerNodes.push(newNode)
      }
    }
    return containerNodes
  }

  function addNodesToContainers() {
    for(var i = 0; i < containers.length; i++) {
      var c = containers[i]
      var containerNodes = addNodes(c.x, c.y, c.w, c.h)
      c.nodes = containerNodes
      if (initialCoords[i]) {
      }
      
    }
  }



  function TextContainer(x,y,w,h, div) {
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
    this.leftBound = x;
    this.topBound = y;
    this.bottomBound = y + h;
    this.rightBound = x + w;
    this.div = null;
    this.text = null;
    this.nodes = [];
    this.contained = []
    this.on = false;
    this.nodeDistances = [];
    this.activeNode = null;

    // this.mag = 4000;
    // this.mag = 4;
    this.textX = 0;
    this.textY = 0;
    this.textWidth = null; 
    this.textHeight = null;
    this.halfWidth = null;
    this.halfHeight = null;
  }

  TextContainer.prototype.reset = function (x,y,w,h){
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
    this.leftBound = x;
    this.topBound = y;
    this.bottomBound = y + h;
    this.rightBound = x + w;
  }


  TextContainer.prototype.contains = function(x,y) {
    if (x < this.leftBound) {
      this.toggleOff()
      return false
    }
    if (x > this.rightBound) {
      this.toggleOff()
      return false
    }
    if (y < this.topBound) {
      this.toggleOff()
      return false
    }
    if (y > this.bottomBound) {
      this.toggleOff()
      return false
    }

    this.toggleOn()
    return true
  }

  TextContainer.prototype.setActiveNode = function(i) {
    this.activeNode = this.nodes[i];
  }

  TextContainer.prototype.setText = function(text, x, y) {
    this.text = text;
    var rect = text.getBoundingClientRect()
    // this.rect = rect;
    
    this.textWidth = rect.width
    this.textHeight = rect.height
    this.halfWidth = this.textWidth / 2;
    this.halfHeight= this.textHeight / 2;
    this.textX = x 
    this.textY = y 
  }

  TextContainer.prototype.getTextProps= function(text) {
    var rect = this.text.getBoundingClientRect()
    
    this.textWidth = rect.width
    this.textHeight = rect.height
    this.halfWidth = this.textWidth / 2;
    this.halfHeight= this.textHeight / 2;
  }
    
  TextContainer.prototype.resetTextRef = function(text) {
    this.text = text;
  }

  TextContainer.prototype.resetWidthAndHeight = function(text) {
    var rect = this.text.getBoundingClientRect()
    this.textWidth = rect.width;
    this.textHeight = rect.height;
  }
  TextContainer.prototype.moveText = function(x,y) {
    this.text.css("transform", "translate3d("+x+"px,"+y+"px,0)")
  }

  TextContainer.prototype.setContained = function(idx, val) {
    this.contained[idx] = val;
  }

  TextContainer.prototype.containsPoint = function(x,y) {
        return this.leftBound <= x && x <= this.rightBound &&
       this.topBound <= y && y <= this.bottomBound;
  }

  TextContainer.prototype.resetNodeDistances = function() {
    for (var i = 0; i < this.nodeDistances.length; i++) {
      this.nodeDistances[i] = 0;
    }
  }

  TextContainer.prototype.checkContained = function() {

    for (var i = 0; i < this.contained.length; i++) {
      for (var j = 0; j < this.nodes.length; j++) {
        if (this.contained[i]) {
          this.addDistance(this.contained[i], this.nodes[j], j)
        }
      }
    }
  }

  TextContainer.prototype.updateNodes = function() {
    this.resetNodeDistances()
    this.checkContained()
    this.getMax()
  }

  TextContainer.prototype.updateText = function() {

    var diffX = this.activeNode.x - (this.textX + (1 *this.halfWidth));
    var diffY = this.activeNode.y - (this.textY + (1 * this.halfHeight));

    diffX /= MAG;
    diffY /= MAG;

    this.textX += diffX;
    this.textY += diffY;
    

    if (this.textX + this.textWidth > w) {
      this.textX = w - this.textWidth;
    }
    if (this.textY + this.textHeight > h) {
      this.textY = h - this.textHeight;
    }
    if (this.textX < 0) {
      this.textX = 0;
    }
    if (this.textY < 0) {
      this.textY  = 0;
    }

    return [this.textX, this.textY];
  }

  TextContainer.prototype.addDistance = function(contained, node, idx) {
    var dx = node.x - contained.centerX;
    var dy = node.y - contained.centerY;

    var d = Math.sqrt(dx * dx + dy * dy);
    this.nodeDistances[idx] += d;
  }

  TextContainer.prototype.getMax = function() {
    var result = indexOfMax(this.nodeDistances)
    if (result.val) {
      this.setActiveNode(result.idx)
    } else {
      this.setActiveNode(4)
    }
  }


  TextContainer.prototype.toggleOff = function() {
    this.div.removeClass("on")
    this.on = false;
  }
  TextContainer.prototype.toggleOn = function() {
    this.div.addClass("on")
    this.on = true;
  }

  function indexOfMax(arr) {
    if (arr.length === 0) {
        return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
        if (arr[i] > max) {
            maxIndex = i;
            max = arr[i];
        }
    }

    return {idx: maxIndex, val: max};
  }

  function Node(x, y) {
    this.x = x;
    this.y = y;
    this.div = null
  }


export class HomePage extends Component {
  constructor(props) {
    super(props);
    this.init = false;

    this.scrolling = false;
    this.clickOffsetY = 0;
    this.currentOffsetY = 0;
    this.offsetDiff = 0;

    this.totalNumImages = 0;
    this.imageLoadCounter = 0;

    
    // this.imageBase = "http://ec2-54-202-92-145.us-west-2.compute.amazonaws.com/";
    // this.imageBase = "https://d355psrnhl58dk.cloudfront.net/"
    this.imageBase = ""
    this.featuredObj = {};
    this.featuredRefs = [];
    this.mobileFeaturedBin = {};

    this.featuredBins = {"pr": {}, "sp": {}, "fu": {}, "li": {}};
    this.featuredTransforms = {};
    this.featuredWidths = {"pr": [], "fu": [], "sp": [], li: []};
    this.featuredImages = [];

    this.defaultPos = {x: 0, y: 0};

    this.time = 0;
    this.perlinVals = [];
    this.XSPEED = .00003;
    this.YSPEED = .00003;

    this.numImages = 8;
    this.numProducts = 12;
    this.numFurniture = 5;
    this.numSpaces = 3;
    
    this.num = 2;
    this.transitionTime = 1100;
    this.sectionHeightOffset = 100
    this.w = window.innerWidth;
    this.h = window.innerHeight;


    // this.transforms = [[560, 67], [200, 67] , [914, 64], [85, 232], [240, 186], [500, 316], [241, 629], [1146, 426]];
    this.transforms = [
                        [.388 * this.w, .08375 * this.h ],
                        [.1388 * this.w, .08375 * this.h],
                        [.6347 * this.w, .08 * this.h ],
                        [.059027 * this.w, .29 * this.h],
                        [.16666 * this.w, .2325 * this.h ],
                        [.34722 * this.w, .395 * this.h],
                        [.16736 * this.w, .78625 * this.h ],
                        [.7958 * this.w, .5325 * this.h ],
                      ];


    this.textTransforms = [];
    this.imageContainers = [];
    this.textContainers = [];

    this.sectionOffsets = {"products": 0, "spaces": 0, "lighting": 0};
    this.deltaY = 0;
    this.gridHeight = 0;
    this.currentSection = "Products";

    this.isMobile = checkIsMobile()
    this.SENSITIVITY = 3.5;
    this.MAG = 10;
    this.mobileTransform = 0;
    this.mobileDiff = 0;
    this.mobileCurr = 0;
    this.maxTransform = -1 * (1900 - this.w);
    this.maxTransform = -1 * (1460 - this.w);
    // let initMobileStart = this.maxTransform / 2;
    let initMobileStart = 0;
    this.mobileTransform = initMobileStart;
    this.mobileCurr = initMobileStart;

    this.sides = [
      ["left", "right"],
      ["left", "right"],
      ["left", "bottom"],
      ["left", "left"],
      ["right", "right"],
      ["left", "left"],
      ["right", "right"],
      ["left", "left"],
      ["right", "right"],
    ];

    this.sidesMobile = [
      ["left", "right"],
      ["left", "bottom"],
      ["top", "right"],
      ["left", "bottom"],
      ["top", "bottom"],
      ["left", "left"],
      ["right", "right"],
      ["left", "left"],
      ["top", "right"],
    ];



    this.state = {
      text: ["An", "industrial", "design", "office", "that", "designs", "products,", "furniture", "& spaces"],
      projects: {
        featured: [],
        all: {
          products: [],
          furniture: [],
          spaces: [],
          lighting: [],
          featured: []
        }
      },
      activeProject: {
        id: null,
        image: undefined,
        name: undefined
      },
      infoVisible: false,
      aboutVisible: false,
      projectVisible: false,
      workClass: "",
      homeOrWork: true,
      transforms: [],
      textTransforms: [],
      gridTransforms: {products: [], furniture: [], spaces: [], lighting: []},
      productHeights: [],
      furnitureHeights: [],
      spaceHeights: [],
      lightingHeights: [],
      points: [],
      deltaY: 0,
      mobileTransform: initMobileStart,
      transitioning: false,
      section: "Products",
      overlay: true
    }
  }

  _initOld() {
    var checkIE = checkIsIE()
    if (checkIE) {
      // var URL = 'http://ec2-54-202-92-145.us-west-2.compute.amazonaws.com/api/all.json';
    } else {
      var URL = 'https://d355psrnhl58dk.cloudfront.net/api/all.json' // older cdn
      // var URL = "https://dthqriueal7eo.cloudfront.net/api/all.json"    // newer cdn
    }

    let resp = {featured: [],  all: []};
    var that = this;
    fetch(URL)
      .then((data) => data.json())
      .then((json) => {


        let products = [];
        let furniture = [];
        let spaces = [];
        let lighting = [];
        let featured = [];
        json.data.forEach(function(item, idx) {
          if (item && item.section === "homePage") {
            resp.featured = item.projects
          }
        })

        let featuredObj = {};

        for (let key in resp.featured) {
          let featuredId = resp.featured[key].id;
          featuredObj[featuredId] = resp.featured[key].title
        }

        that.featuredObj = featuredObj;
        var currentIndex = 0;
        json.data.forEach(function(item, idx) {
         if (item && item.section === "projects") {
            switch(item.type.value) {
              case 'product':
                if (item.id in featuredObj) {
                  featured.push(item)
                  that.featuredRefs.push("pr" + products.length);
                  that.featuredBins["pr"][products.length] = true;
                  currentIndex ++;
                } 

                products.push(item)
                
                break;
              case 'furniture':
                if (item.id in featuredObj) {
                  featured.push(item)
                  that.featuredRefs.push("fu" + furniture.length)
                  that.featuredBins["fu"][furniture.length] = true
                  currentIndex ++;
                }

                furniture.push(item)
                
                break;
              case 'space':
                if (item.id in featuredObj) {
                  featured.push(item)
                  that.featuredRefs.push("sp" + spaces.length)
                  that.featuredBins["sp"][spaces.length] = true
                  currentIndex ++;
                } 

                spaces.push(item)
                
                break;
              case 'lighting': 
                if (item.id in featuredObj) {
                  featured.push(item)
                  that.featuredRefs.push("li" + spaces.length)
                  that.featuredBins["li"][lighting.length] = true
                  currentIndex ++;
                } 

                lighting.push(item)
                
                break;
              default:
                console.log("no section err")
            }
          }
        })
        resp.all = {
          products: products,
          furniture: furniture,
          spaces: spaces,
          lighting: lighting,
          featured: featured
        }

        for (var i = 0; i < 6; i++) {
          that.mobileFeaturedBin[that.featuredRefs[i]] = i + 1
        }

        that.totalNumImages = products.length + furniture.length + spaces.length + lighting.length

        that.setState({projects: resp})
        if ((that.props.location.pathname === "/" ||  that.props.location.pathname === "/work")) {
          that.init = true;
          that.setUpHomePage()
        }
        
    });

    if (!(this.props.location.pathname === "/" ||  this.props.location.pathname === "/work")) {
      this.setState({workClass: "work", homeOrWork: false})
    } else if (this.props.location.pathname === "/work") {
      this.setState({workClass: "work", homeOrWork: true})
    } 

    window.addEventListener("mousemove", this.handleScrollBarMove.bind(this))   
    window.addEventListener("mouseup", this.handleScrollBarUp.bind(this))   

  }
  
  _init() {
    var checkIE = checkIsIE()
    if (checkIE) {
      // var URL = 'http://ec2-54-202-92-145.us-west-2.compute.amazonaws.com/api/all.json';
    } else {
      var URL = 'https://d355psrnhl58dk.cloudfront.net/api/all.json' // older cdn
      // var URL = "https://dthqriueal7eo.cloudfront.net/api/all.json"    // newer cdn
    }

    let resp = {featured: [],featuredNew: [], allNew: [],  all: []};
    let products = [];
    let furniture = [];
    let spaces = [];
    let lighting = [];
    let featured = [];
    var that = this;
    // fetch(URL)
    //   .then((data) => data.json())
    //   .then((json) => {

        resp.featuredNew = that.props.homePageFeaturedItems;
        let featuredObj = {};
        
        that.props.homePageFeaturedItems.forEach(item => {
          featuredObj[item.fields.title] = true;
        });
        that.featuredObj = featuredObj;

   

        var currentIndex = 0;
        Object.keys(that.props.projects).forEach(key => {
          const project = that.props.projects[key];
          const {projectType, title} = project.fields
          switch(projectType) {
            case 'Product':
              if (title in featuredObj) {
                featured.push(project)
                that.featuredRefs.push("pr" + products.length);
                that.featuredBins["pr"][products.length] = true;
                currentIndex ++;
              } 

              products.push(project)
              
              break;
            case 'Furniture':
              if (title in featuredObj) {
                featured.push(project)
                that.featuredRefs.push("fu" + furniture.length)
                that.featuredBins["fu"][furniture.length] = true
                currentIndex ++;
              }

              furniture.push(project)
              
              break;
            case 'Space':
              if (title in featuredObj) {
                featured.push(project)
                that.featuredRefs.push("sp" + spaces.length)
                that.featuredBins["sp"][spaces.length] = true
                currentIndex ++;
              } 

              spaces.push(project)
              
              break;
            case 'Lighting': 
              if (title in featuredObj) {
                featured.push(project)
                that.featuredRefs.push("li" + spaces.length)
                that.featuredBins["li"][lighting.length] = true
                currentIndex ++;
              } 

              lighting.push(project)
              
              break;
            default:
              console.log("no section err")
          }
        });


        resp.all = {
          products: products,
          furniture: furniture,
          spaces: spaces,
          lighting: lighting,
          featured: featured
        }
        
        for (var i = 0; i < 6; i++) {
          that.mobileFeaturedBin[that.featuredRefs[i]] = i + 1
        }

        that.totalNumImages = products.length + furniture.length + spaces.length + lighting.length

        that.setState({projects: resp}, () => {
          if ((that.props.location.pathname === "/" ||  that.props.location.pathname === "/work")) {
            that.init = true;
            that.setUpHomePage()
          }
        });

        
    // });

    if (!(this.props.location.pathname === "/" ||  this.props.location.pathname === "/work")) {
      this.setState({workClass: "work", homeOrWork: false})
    } else if (this.props.location.pathname === "/work") {
      this.setState({workClass: "work", homeOrWork: true})
    } 

    window.addEventListener("mousemove", this.handleScrollBarMove.bind(this))   
    window.addEventListener("mouseup", this.handleScrollBarUp.bind(this))   

  }


  // componentWillMount() {
  //   // this._initOld()
  // } 

  
  setUpHomePage() {
    this.setUpImageContainers()
    this.setUpTextContainers()
    this.addNodesToContainers()
    
    var font = new FontFaceObserver('Neue-Haas');
    var that = this;
    font.load().then(function () {
      setTimeout(function() {
        that.getTextSizes()
      }, 250)
    });

    
    setTimeout(function() {
      that.updateGridPosition()
      if (that.props.location.pathname === "/work") {
        that.paused = true;
      }   
    }, 300)

    this.updateContainers()
    if (!this.isMobile) {
      requestAnimationFrame(this.update.bind(this));
    } else {
      this.setUpMotionListener()
      requestAnimationFrame(this.updateMobile.bind(this));
    }

    // this.removeOverlay()
  }

  removeOverlay() {
    this.setState({
      overlay: false
    })
  }

  getTextSizes() {
    for (var i = 0; i < this.textContainers.length; i++) {
      this.textContainers[i].getTextProps()
    }
  }

  setUpImageContainers() {
    var container;
    this.state.projects.featuredNew.map(item => this.perlinVals.push({dx: Math.random() * 1000, dy: Math.random() * 1000}))

    // var featuredImages = [];
    var count = 0;
    for (var i = 0; i < this.state.projects.all.products.length; i++) {
      if (this["image_pr_featured" + i]) {

        container = new ImageContainer(this["image_pr_featured" + i], i, this.perlinVals[count].dx, this.perlinVals[count].dy , this.w, this.h, this.transforms[count][0], this.transforms[count][1], count);
        this.imageContainers.push(container)
        this.featuredTransforms["pr" + i] = count;

        count++;
      }
    }

    var i;
    for (i = 0; i < this.state.projects.all.furniture.length; i++) {
      if (this["image_fu_featured" + i]) {
    
        container = new ImageContainer(this["image_fu_featured" + i], i, this.perlinVals[count].dx, this.perlinVals[count].dy , this.w, this.h, this.transforms[count][0], this.transforms[count][1]);
        this.imageContainers.push(container)
        this.featuredTransforms["fu" + i] = count;

        count++;
      }
    }
    for (i = 0; i < this.state.projects.all.spaces.length; i++) {
      if (this["image_sp_featured" + i]) {
        
        container = new ImageContainer(this["image_sp_featured" + i], i, this.perlinVals[count].dx, this.perlinVals[count].dy , this.w, this.h, this.transforms[count][0], this.transforms[count][1]);
        this.imageContainers.push(container)
        this.featuredTransforms["sp" + i] = count;

        count++;
      }
    }
    for (i = 0; i < this.state.projects.all.lighting.length; i++) {
      if (this["image_li_featured" + i]) {
        
        container = new ImageContainer(this["image_li_featured" + i], i, this.perlinVals[count].dx, this.perlinVals[count].dy , this.w, this.h, this.transforms[count][0], this.transforms[count][1]);
        this.imageContainers.push(container)
        this.featuredTransforms["li" + i] = count;

        count++;
      }
    }

  }

  stopAnimation() {
    this.paused = true;
  }

  startAnimation() {
    if (!this.paused) {return}
    this.paused = false;
  }

  setUpTextContainers() {
    var container;
    if (!this.isMobile) {
      for (var i = 0; i < this.state.text.length; i++) {
        var x,y, width, height;
        x = containerLocationsPercent[i][0] / 100 * this.w;
        y = containerLocationsPercent[i][1] / 100 * this.h;

        width = containerSizesPercent[i][0] / 100 * this.w;
        height = containerSizesPercent[i][1] / 100 * this.h;
        var container = new TextContainer(
          x,
          y,
          width,
          height
        )

        this.textContainers.push(container)
        window.textContainers = this.textContainers
      }
    } else {
      for (var i = 0; i < this.state.text.length; i++) {
        var x,y, width, height;
        x = containerLocationsMobile[i][0]
        y = containerLocationsMobile[i][1]

        width = containerSizesPercent[i][0] / 100 * this.w;
        height = containerSizesPercent[i][1] / 100 * this.h;
        var container = new TextContainer(
          x,
          y,
          width,
          height
        )

        this.textContainers.push(container)
      }
    }

  }

  addNodesToContainers() {
    for(var i = 0; i < this.textContainers.length; i++) {
      var c = this.textContainers[i]
      var containerNodes = addNodes(c.x, c.y, c.w, c.h)
      c.nodes = containerNodes
      c.mag = 100;
      var coords;
      if (!this.isMobile) {
        coords = initialCoordsPercent
      } else {
        coords = initialCoordsPercentMobile
        coords = containerLocationsMobile
      }
      
      if (initialCoords[i] && !this.isMobile) {
        c.setText( this['text' + i], coords[i][0] / 100 * this.w, coords[i][1] / 100 * this.h )
      } else if (initialCoords[i] && this.isMobile) {
        c.setText( this['text' + i], coords[i][0], coords[i][1] )
      }
    }
  }

  resetContainers() {
    for (var i = 0; i < this.state.text.length; i++) {
      var x,y;
      var width,height;
      x = containerLocationsPercent[i][0] / 100 * this.w;
      y = containerLocationsPercent[i][1] / 100 * this.h;

      width = containerSizesPercent[i][0] / 100 * this.w;
      height = containerSizesPercent[i][1] / 100 * this.h;

      this.textContainers[i].reset(x,y,width,height);

    }
  }

  resetNodes() {
    for (var i = 0; i < this.textContainers.length; i++) {
      var c = this.textContainers[i]
      var containerNodes = addNodes(c.x, c.y, c.w, c.h,)
      c.nodes = containerNodes
    }
  }

  update() {
    if (this.paused) {
      if ((this.deltaY != this.state.deltaY) && !this.state.transitioning) {
        var diff = this.deltaY - this.state.deltaY;
        diff /= 10;
        this.setState({deltaY: this.state.deltaY + diff});
      }
    } else {
      this.updateContainers()
      this.drawLines()
      this.setState({transforms: this.transforms, textTransforms: this.textTransforms, points: this.points});
    }

    if (!this.isMobile) {
      this.currentOffsetY = (this.state.deltaY / this.gridHeight) * (this.h)
      this.checkSectionOffsets()
      requestAnimationFrame(this.update.bind(this));
    }
  }

  setUpMotionListener() {
    if (typeof DeviceMotionEvent.requestPermission === 'function') {
      // iOS 13+
      document.addEventListener("click", () => {
        DeviceMotionEvent.requestPermission()
        .then(response => {
          if (response == 'granted') {
            window.addEventListener("devicemotion", this.handleMotion.bind(this), true);
          }
        })
        .catch(console.error)
      })

    } else {
      window.addEventListener("devicemotion", this.handleMotion.bind(this), true);
    }

  }

  handleMotion(ev) {

    if (ev.rotationRate) {
      var val = Math.floor(ev.rotationRate.beta);
      this.mobileTransform += (val / this.SENSITIVITY);
      if (this.mobileTransform < this.maxTransform) {
        this.mobileTransform =this.maxTransform;
      } else if (this.mobileTransform > 0) {
        this.mobileTransform = 0;
      }
    } 
  }

  updateMobile() {
    this.mobileDiff = this.mobileTransform - this.mobileCurr;
    this.mobileCurr += this.mobileDiff / this.MAG;
     this.setState({
      mobileTransform: this.mobileCurr
    })
    requestAnimationFrame(this.updateMobile.bind(this))
  }

  drawLines() {
    var offsetLeft, offsetTop, distFromCenter,
    offsetX = 10 , offsetY = 5;
    distFromCenter = 3;
    var topNode;
    var points = [];
    var sides;
    if (!this.isMobile) {
      sides = this.sides;
    } else {
      sides = this.sidesMobile
    }
    
    // let sides = 
    points.push([this.textContainers[0].textX + this.textContainers[0].textWidth, this.textContainers[0].textY + this.textContainers[0].textHeight / 2 ]);
    for (var i = 1 ; i < this.textContainers.length; i++) {
      switch(sides[i][0]) {
          case "top":
              points.push([this.textContainers[i].textX + this.textContainers[i].textWidth / 2, this.textContainers[i].textY])
              break;
          case "right":
              points.push([this.textContainers[i].textX + this.textContainers[i].textWidth + offsetX, this.textContainers[i].textY + this.textContainers[i].textHeight / 2 - distFromCenter])
              break;
          default:
              points.push([this.textContainers[i].textX - offsetX, this.textContainers[i].textY + this.textContainers[i].textHeight / 2 - distFromCenter])
      }

      // starting point
      switch(sides[i][1]) {
          case "bottom":
              points.push([this.textContainers[i].textX + this.textContainers[i].textWidth / 2, this.textContainers[i].textY + this.textContainers[i].textHeight])
              break;
          case "left" :
              points.push([this.textContainers[i].textX - offsetX, this.textContainers[i].textY + this.textContainers[i].textHeight / 2 + distFromCenter])
              break;
          default:
              points.push([this.textContainers[i].textX + this.textContainers[i].textWidth + offsetX, this.textContainers[i].textY + this.textContainers[i].textHeight / 2 + distFromCenter])
      }       

    }
    this.points = points;
  }

  updateContainers() {
      this.time++;

      var transforms = [];
      this.textTransforms = [];
      var x,y, diff;
      for (var i = 0; i < this.state.projects.featuredNew.length; i++) {

        // this.perlinVals[i]
        this.perlinVals[i].dx += this.XSPEED;
        this.perlinVals[i].dy += this.YSPEED;
        
        x = map(generator.getVal(this.perlinVals[i].dx), 0, 1, 0 - this.imageContainers[i].diffX, this.w - this.imageContainers[i].diffX);
        y =  map(generator.getVal(this.perlinVals[i].dy), 0, 1, 0 - this.imageContainers[i].diffY, this.h - this.imageContainers[i].diffY);
        x -= this.imageContainers[i].w / 2
        y -= this.imageContainers[i].h / 2
        

        if (x > this.w - this.imageContainers[i].w) {
          diff = x - (this.w - this.imageContainers[i].w) ;
          x = (this.w - this.imageContainers[i].w)  - diff
        }

        if (x < 0) {
          diff = 0 - x;
          x = 0 + diff
        }

        if (y > this.h - this.imageContainers[i].h) {
          diff = y - (this.h - this.imageContainers[i].h);
          y = (this.h - this.imageContainers[i].h) - diff
        }

        if (y < 0) {
          diff = 0 - y;
          y = 0 + diff
        }

        this.imageContainers[i].update(x,y)

        this.transforms[i] = [x,y];

        for (var j = 0; j < this.textContainers.length; j++) {
          if (this.textContainers[j].containsPoint(this.imageContainers[i].centerX, this.imageContainers[i].centerY)) {
            this.textContainers[j].setContained(i, this.imageContainers[i])
          } else {
            this.textContainers[j].setContained(i, null)
          }
        }
    }

    for (var j = 0; j < this.textContainers.length; j++) {
        this.textContainers[j].updateNodes()
        this.textTransforms.push(this.textContainers[j].updateText())
    }
  }

  componentDidMount() {
    var that = this;
    this._init()

    window.addEventListener('resize', function() {
      that.handleResize()
    })
  }


  imageDidLoad() {
    this.imageLoadCounter++;
    if (this.imageLoadCounter == this.totalNumImages) {
      this.getFeaturedWidths()
      this.removeOverlay()

      var that = this;
      setTimeout(function() {
        that.getImageHeights()
        that.updateGridPosition()
      },300)
    }
  }

  handleResize() {
    this.updateGridPosition()
    this.resetTextWidthAndHeight()
    this.w = window.innerWidth;
    this.h = window.innerHeight;
    this.resetContainers()
    this.resetNodes()

    this.getFeaturedWidths()
    this.getImageHeights()

    MAG = 100;
    setTimeout(function() {
      MAG = 4000
    }, 3000)
  }

  resetTextWidthAndHeight() {
    if (!this['text' + 0]) {
      var that = this;
      setTimeout(function() {
        that.resetTextWidthAndHeight()
      },100)
      return;
    }

   for (var i = 0 ; i < this.textContainers.length ; i++) {
      this.textContainers[i].resetTextRef(this['text' + i])
      this.textContainers[i].resetWidthAndHeight()
    }
  }

  updateGridPosition() {
    let prBounds = this.setProductRows()
    let fuBounds = this.setFurnitureRows()
    let spBounds = this.setSpaceRows();
    let liBounds = this.setLightingRows();

    let bounds = {
      products: prBounds,
      furniture: fuBounds,
      spaces: spBounds,
      lighting: liBounds
    }

    var rect = ReactDOM.findDOMNode(this.refs.imageRows).getBoundingClientRect()
    this.gridHeight = (-1 * rect.height)
    this.updateSectionOffsets()
    this.setState({gridTransforms: bounds })
  }

  getFeaturedWidths() {
    var products = [], furniture = [], spaces = [], lighting = [];
    var prImage = [], fuImage = [], spImage = [], liImage = [];

    for (var i = 0; i < this.state.projects.all.products.length; i++) {
      if (this["image_pr_featured" + i]) {
        products.push(this["image_pr_featured" + i].getBoundingClientRect().width)
        prImage.push(this["image_pr_featured" + i])
      } else {
         products.push(null)
      }
    }
    for (var i = 0; i < this.state.projects.all.furniture.length; i++) {
      if (this["image_fu_featured" + i]) {
        furniture.push(this["image_fu_featured" + i].getBoundingClientRect().width)
        fuImage.push(this["image_fu_featured" + i])
      } else {
        furniture.push(null)
      }
    }
    for (var i = 0; i < this.state.projects.all.spaces.length; i++) {
      if (this["image_sp_featured" + i]) {
        spaces.push(this["image_sp_featured" + i].getBoundingClientRect().width)
        spImage.push(this["image_sp_featured" + i])
      } else {
        spaces.push(null)
      }
    }
    for (var i = 0; i < this.state.projects.all.lighting.length; i++) {
      if (this["image_li_featured" + i]) {
        lighting.push(this["image_li_featured" + i].getBoundingClientRect().width)
        liImage.push(this["image_li_featured" + i])
      } else {
        lighting.push(null)
      }
    }

    this.featuredWidths = {
      "pr": products,
      "fu": furniture,
      "sp": spaces,
      "li": lighting
    }
    this.featuredImages = prImage.concat(fuImage).concat(spImage).concat(liImage)
  }

  getImageHeights() {
    var productHeights = [], furnitureHeights = [], spaceHeights = [], lightingHeights = [];

    for (var i = 0; i < this.state.projects.all.products.length; i++) {
      if (this["image_pr_featured" + i]) {
        productHeights.push(this["image_pr_featured" + i].getBoundingClientRect().height)
      } else {
        productHeights.push(this["image" + i].getBoundingClientRect().height)
      }
    }
    for (var i = 0; i < this.state.projects.all.furniture.length; i++) {
      if (this["image_fu_featured" + i]) {
        furnitureHeights.push(this["image_fu_featured" + i].getBoundingClientRect().height)
      } else {
        furnitureHeights.push(this["image_fu" + i].getBoundingClientRect().height)
      }
    }
    for (var i = 0; i < this.state.projects.all.spaces.length; i++) {
      if (this["image_sp_featured" + i]) {
        spaceHeights.push(this["image_sp_featured" + i].getBoundingClientRect().height)
      } else {
        spaceHeights.push(this["image_sp" + i].getBoundingClientRect().height)
      }
    }

    for (var i = 0; i < this.state.projects.all.lighting.length; i++) {
      if (this["image_li_featured" + i]) {
        lightingHeights.push(this["image_li_featured" + i].getBoundingClientRect().height)
      } else {
        lightingHeights.push(this["image_li" + i].getBoundingClientRect().height)
      }
    }   

    this.setState({
      productHeights: productHeights,
      furnitureHeights: furnitureHeights,
      spaceHeights: spaceHeights,
      lightingHeights: lightingHeights
    })
  }

  setProductRows() {
    var positions;

    let numRows = Math.ceil(this.state.projects.all.products.length / 4);
    let numPerRow = 4;
    var bounds = [];
    for (var i = 0; i < numRows; i++) {
      for (var j = 0; j < numPerRow; j++) { 
         if (this.refs.imageRows.refs["pr" + i]["ip" + j]) {
          bounds.push(this.refs.imageRows.refs["pr" + i]["ip" + j].getBoundingClientRect())
         }
      }
    }
    return bounds
  }

  setFurnitureRows() {
    var positions;
    let numRows = Math.ceil(this.state.projects.all.furniture.length / 4);
    let numPerRow = 4;
    var bounds = [];
    for (var i = 0; i < numRows; i++) {
      for (var j = 0; j < numPerRow; j++) { 
         if (this.refs.imageRows.refs["fu" + i]["ip" + j]) {
          bounds.push(this.refs.imageRows.refs["fu" + i]["ip" + j].getBoundingClientRect())
         }
      }
    }
    return bounds
  }

  setSpaceRows() {
    var positions;
    let numRows = Math.ceil(this.state.projects.all.spaces.length / 4);
    let numPerRow = 4;
    var bounds = [];
    for (var i = 0; i < numRows; i++) {
      for (var j = 0; j < numPerRow; j++) { 
         if (this.refs.imageRows.refs["sp" + i]["ip" + j]) {
          bounds.push(this.refs.imageRows.refs["sp" + i]["ip" + j].getBoundingClientRect())
         }
      }
    }
    return bounds
  }

  setLightingRows() {
    var positions;
    let numRows = Math.ceil(this.state.projects.all.lighting.length / 4);
    let numPerRow = 4;
    var bounds = [];


    for (var i = 0; i < numRows; i++) {
      for (var j = 0; j < numPerRow; j++) { 
         if (this.refs.imageRows.refs["li" + i]["ip" + j]) {
          bounds.push(this.refs.imageRows.refs["li" + i]["ip" + j].getBoundingClientRect())
         }
      }
    }
    return bounds
  }

  updateSectionOffsets() {
    // if (this.h)
    if (!this.isMobile) {
      var furniture = this.refs.imageRows.refs.fu0.ip0.getBoundingClientRect().top;
      var products = this.refs.imageRows.refs.pr0.ip0.getBoundingClientRect().top;
      var spaces = this.refs.imageRows.refs.sp0.ip0.getBoundingClientRect().top;
      var lighting = this.refs.imageRows.refs.li0.ip0.getBoundingClientRect().top;

    } else {
      let furnitureImage = this["image_fu0"] || this["image_fu_featured0"];
      let productImage = this["image0"] || this["image_pr_featured0"];
      let spaceImage = this["image_sp0"] || this["image_sp_featured0"];
      let lightingImage = this["image_li0"] || this["image_li_featured0"];

      var furniture = furnitureImage.getBoundingClientRect().top;
      var products = productImage.getBoundingClientRect().top;
      var spaces = spaceImage.getBoundingClientRect().top;
      var lighting = lightingImage.getBoundingClientRect().top;
    }
    
    this.sectionOffsets['spaces'] = spaces;
    this.sectionOffsets['furniture'] = furniture;
    this.sectionOffsets['products'] = products;
    this.sectionOffsets['lighting'] = lighting;
  }

  checkSectionOffsets() {

    if ((this.state.deltaY - (this.h / 2) - this.sectionHeightOffset) < this.sectionOffsets['spaces'] * -1 ) {
      if (this.currentSection != "Spaces") {
        this.currentSection = "Spaces"
        this.setState({section: "Spaces"})
      }
    } else if ((this.state.deltaY - (this.h / 2) - this.sectionHeightOffset) < this.sectionOffsets['lighting'] * -1 ) {
      if (this.currentSection != "Lighting") {
        this.currentSection = "Lighting"
        this.setState({section: "Lighting"})
      }
    } else if ((this.state.deltaY - (this.h / 2)  - this.sectionHeightOffset) < this.sectionOffsets['furniture'] * -1 ) {
      if (this.currentSection != "Furniture") {
        this.currentSection = "Furniture"
        this.setState({section: "Furniture"})
      }
    } else {
      if (this.currentSection != "Products") {
        this.currentSection = "Products"
        this.setState({section: "Products"})
      }
    }
  }

  handleWheel(ev) {  
    if (!this.paused) {return}
    this.deltaY = this.deltaY - ev.deltaY;
    if (this.deltaY > 0) {
      this.deltaY = 0;
    }
    if (this.deltaY < (this.gridHeight + this.h)) {
      this.deltaY = (this.gridHeight + this.h);
    }
  }

  handleScroll(ev) {
    if (this.wrapper.scrollTop >= this.sectionOffsets.spaces - (this.h / 2) - 50) {
      if (this.currentSection != "Spaces") {
        this.currentSection = "Spaces"
        this.setState({section: "Spaces"})
      }
    } else if (this.wrapper.scrollTop >= this.sectionOffsets.lighting - (this.h / 2) - 50) {
      if (this.currentSection != "Lighting") {
        this.currentSection = "Lighting"
        this.setState({section: "Lighting"})
      }
    } else if (this.wrapper.scrollTop >= this.sectionOffsets.furniture - (this.h / 2) - 50) {
      if (this.currentSection != "Furniture") {
        this.currentSection = "Furniture"
        this.setState({section: "Furniture"})
      }
    } else {
      if (this.currentSection != "Products") {
        this.currentSection = "Products"
        this.setState({section: "Products"})
      }
    } 
  } 

  handleScrollBarDown(ev) {
    ev.preventDefault()
    this.scrolling = true;
    this.clickOffsetY = ev.clientY;
    this.offsetDiff = this.clickOffsetY - this.currentOffsetY;

  } 
  handleScrollBarMove(ev) {
    if (this.scrolling) {
      this.currentOffsetY = ev.clientY - this.offsetDiff;
      if (this.currentOffsetY < 0) {this.currentOffsetY = 0};
      if (this.currentOffsetY + 100 > this.h) {this.currentOffsetY = this.h - 100};
      var scrollPercent = this.currentOffsetY / (this.h - 100);
      this.deltaY =  scrollPercent * (this.gridHeight + this.h);
    }
  }

  handleScrollBarUp(ev) {
    if (this.scrolling) {
      this.scrolling = false;
    }
  } 

  componentWillReceiveProps(nextProps, prevProps) {
    

    if (!(nextProps.location.pathname === "/" ||  nextProps.location.pathname === "/work")) {
      this.setState({workClass: "work", homeOrWork: false})
      this.stopAnimation()
    } else if (nextProps.location.pathname === "/work") {

      this.setState({workClass: "work",homeOrWork: true, transitioning: true})
      this.stopAnimation()
      var that = this;
      let time = this.props.location.pathname === "/" ? this.transitionTime : 0
      setTimeout(function() {
        that.setState({transitioning: false})
        if (!that.init) {
          that.init = true;
          that.setUpHomePage()
        }
      }, time)

      if (this.isMobile && this.props.location.pathname === "/") {
        // in case the section offsets include images that are featured,
        // which they do right now...

        var that = this;
        setTimeout(function() {
          that.updateSectionOffsets()
        },200)
      }

    } else {
      this.setState({workClass: "",  homeOrWork: true, transitioning: true})
      let that = this;
      this.resetTextWidthAndHeight()
      let transitionTime = this.props.location.pathname === "/work" ? this.transitionTime : 0
      setTimeout(function() {
        that.setState({transitioning: false, deltaY: that.deltaY})
        that.startAnimation()
        if (!that.init) {
          that.init = true;
          that.setUpHomePage()
        }
      }, transitionTime)
    }
  } 

  render() {
    
    let that = this;


    if (this.state.homeOrWork) {
      if (this.state.workClass) {
        var workClass = true;
      } else {
        workClass = false;
      }
      if (this.isMobile) {
        var styles = {
          transform: "translate3d("+this.state.mobileTransform+"px,-300px,0px)"
        }
      } else {
        var styles = {}
      }

      let num = 0;

      return (  
        <div style={styles} className={"homepage " + this.state.workClass} onWheel={this.handleWheel.bind(this)} onScroll={this.handleScroll.bind(this)} ref={(div) => { that.wrapper = div; }}>
          {this.state.text.map((item, i) => <TextElement key={"te" + i} word={item} showWork={workClass} textRef = {el => this['text' + i] = el} className={i} transform={that.textTransforms[i]}></TextElement>)}
          <img className="line line-1" src={this.imageBase + "/images/line-1.svg"} alt=""/>
          <img className="line line-2" src={this.imageBase + "/images/line-2.svg"} alt=""/>
          <img className="line line-3" src={this.imageBase + "/images/line-3.svg"} alt=""/>

          <img className="line line-4" src={this.imageBase + "/images/line-4.svg"} alt=""/>
          <img className="line line-5" src={this.imageBase + "/images/line-5.svg"} alt=""/>
          <img className="line line-6" src={this.imageBase + "/images/line-6.svg"} alt=""/>

          <img className="line line-7" src={this.imageBase + "/images/line-7.svg"} alt=""/>
          <img className="line line-8" src={this.imageBase + "/images/line-8.svg"} alt=""/>
          <Overlay overlay={this.state.overlay}></Overlay>

          {this.state.projects.all.products.map((item, i) => {
            return (
            <ProjectElement 
            isFeatured={i in that.featuredBins["pr"]} 
            isFeaturedMobile={that.mobileFeaturedBin[("pr" + i)]} 
            handleLoad={that.imageDidLoad.bind(this)} 
            projectInfo={item.fields.client ? item.fields.client + ", " + item.fields.year : item.fields.year} 
            projectTitle={item.fields.title} 
            featuredNum={i in that.featuredBins["pr"] ? num++ : false}
            transitioning={that.state.transitioning} 
            imageWidth={that.featuredWidths["pr"][i]} 
            imageSizing={that.state.projects.all.products[i].fields.featuredImageSize} 
            imageContainer={that.imageContainers[i]} 
            deltaY={that.state.deltaY} 
            showWork={workClass} 
            gridTransform={that.state.gridTransforms.products[i]} 
            key={"pe" + (i + 3)} 
            imageRef={ el => i in that.featuredBins["pr"] ? this['image_pr_featured' + i] = el : this['image' + i] = el } 
            imageSrc={this.state.projects.all.products[i].fields.featuredImage.fields.file.url  + "?w=600&fm=jpg&q=85"} 
            className={i} 
            transform={that.transforms[that.featuredTransforms["pr" + i]]} 
            w={that.w}></ProjectElement>)
            })}
            
          {this.state.projects.all.furniture.map((item, i) => <ProjectElement isFeatured={i in that.featuredBins["fu"]} isFeaturedMobile={that.mobileFeaturedBin[("fu" + i)]} handleLoad={that.imageDidLoad.bind(this)} projectInfo={item.fields.client ? item.fields.client + ", " + item.fields.year : item.fields.year} projectTitle={item.fields.title} transitioning={that.state.transitioning} imageWidth={that.featuredWidths["fu"][i]} imageSizing={that.state.projects.all.furniture[i].fields.featuredImageSize} imageContainer={that.imageContainers[i]} deltaY={that.state.deltaY} showWork={workClass} gridTransform={that.state.gridTransforms.furniture[i]} key={"fu" + (i + 3)} imageRef={ el => i in that.featuredBins["fu"] ? this['image_fu_featured' + i] = el : this['image_fu' + i] = el } imageSrc={this.state.projects.all.furniture[i].fields.featuredImage.fields.file.url + "?w=600&fm=jpg&q=85"} className={i} transform={that.transforms[that.featuredTransforms["fu" + i]]} w={that.w}></ProjectElement>)}
          {this.state.projects.all.lighting.map((item, i) => <ProjectElement isFeatured={i in that.featuredBins["li"]}  isFeaturedMobile={that.mobileFeaturedBin[("li" + i)]} handleLoad={that.imageDidLoad.bind(this)} projectInfo={item.fields.client ? item.fields.client + ", " + item.fields.year : item.fields.year} projectTitle={item.fields.title} transitioning={that.state.transitioning} imageWidth={that.featuredWidths["li"][i]} imageSizing={that.state.projects.all.lighting[i].fields.featuredImageSize} imageContainer={that.imageContainers[i]} deltaY={that.state.deltaY} showWork={workClass} gridTransform={that.state.gridTransforms.lighting[i]} key={"li" + (i + 3)} imageRef={el => i in that.featuredBins["li"] ? this['image_li_featured' + i] = el : this['image_li' + i] = el } imageSrc={this.state.projects.all.lighting[i].fields.featuredImage.fields.file.url + "?w=600&fm=jpg&q=85"} className={i} transform={that.transforms[that.featuredTransforms["li" + i]]} w={that.w}></ProjectElement>)}
          {this.state.projects.all.spaces.map((item, i) => <ProjectElement isFeatured={i in that.featuredBins["sp"]} isFeaturedMobile={that.mobileFeaturedBin[("sp" + i)]} handleLoad={that.imageDidLoad.bind(this)} projectInfo={item.fields.client ? item.fields.client + ", " + item.fields.year : item.fields.year} projectTitle={item.fields.title} transitioning={that.state.transitioning} imageWidth={that.featuredWidths["sp"][i]} imageSizing={that.state.projects.all.spaces[i].fields.featuredImageSize} imageContainer={that.imageContainers[i]} deltaY={that.state.deltaY} showWork={workClass} gridTransform={that.state.gridTransforms.spaces[i]} key={"sp" + (i + 3)} imageRef={el => i in that.featuredBins["sp"] ? this['image_sp_featured' + i] = el : this['image_sp' + i] = el } imageSrc={this.state.projects.all.spaces[i].fields.featuredImage.fields.file.url + "?w=600&fm=jpg&q=85"} className={i} transform={that.transforms[that.featuredTransforms["sp" + i]]} w={that.w}></ProjectElement>)}

          <CanvasElement width={this.w} height={this.h} points={this.state.points} showWork={workClass} display={true} background={false}></CanvasElement>
          <GridTitle section={this.state.section}></GridTitle>
          <Scrollbar showWork={workClass} onMouseDown={this.handleScrollBarDown.bind(this)} deltaY={this.state.deltaY} height={this.h} gridHeight={this.gridHeight}></Scrollbar>
          <ImageRows ref={"imageRows"} numProducts={this.state.projects.all.products.length} numFurniture={this.state.projects.all.furniture.length} numSpaces={this.state.projects.all.spaces.length} numLighting={this.state.projects.all.lighting.length} productHeights={this.state.productHeights} furnitureHeights={this.state.furnitureHeights} spaceHeights={this.state.spaceHeights} lightingHeights={this.state.lightingHeights} rowLength={4}></ImageRows>
        </div>
      )
    } else {
      return null
    }

  }
}

class GridTitle extends PureComponent {
    constructor(props) {
      super(props);
    }
    
    render() {
      return(
          <h1 className="grid-title">{this.props.section}</h1>
      )
    }
}


class Overlay extends PureComponent {
    constructor(props) {
      super(props);
    }
    
    render() {
      return(
          <div className={this.props.overlay ? "overlay" : "overlay hide"}></div>
      )
    }
}
  

